import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import NewModal from 'src/components/NewModal'
import Checkbox from 'src/pages/DynamicPage/components/Checkbox'
import { useMediaQuery } from 'src/utilities/hooks'
import Text from 'src/components/Text'
import { Dispatch, RootState } from 'src/utilities/store'
import IconButton from 'src/components/IconButton'
import { getMainHost } from 'src/utilities/functions'

const DATA: Data[] = [
  {
    id: '0',
    title: 'No organization',
    groupHost: '',
    groupDomain: '',
    groupSubdomens: '',
  },
  {
    id: '1',
    title: 'Jammi',
    groupHost: 'https://jammi.lt',
    groupDomain: 'jammi.lt',
    groupSubdomens:
      'tauro-vilnius,tymoturgus-vilnius,sauletekio-vilnius,ateities-vilnius,blindziu-vilnius,ukmerges-vilnius,medeinos-vilnius,talino-vilnius,vyduno-vilnius,pilkalnio-vilnius,luksio-vilnius,naugarduko-vilnius,ozas-vilnius,tilzes-klaipeda,taikos-klaipeda,stanaiciu-mazuriskes,tilzes-siauliai',
  },
  {
    id: '2',
    title: 'Testas',
    groupHost: 'https://testas.lt',
    groupDomain: 'testas.lt',
    groupSubdomens: 'testas-a,testas-b,testas-c,testas-d',
  },
  {
    id: '3',
    title: 'TestasGiedres',
    groupHost: 'https://testasgiedres.lt',
    groupDomain: 'testasgiedres.lt',
    groupSubdomens: 'testasgiedres-a,testasgiedres-b,testasgiedres-c',
  },
  {
    id: '4',
    title: 'Viva Pizza',
    groupHost: 'https://vivapizza.lt',
    groupDomain: 'vivapizza.lt',
    groupSubdomens: '',
  },
  {
    id: '5',
    title: 'Jurgis ir drakonas',
    groupHost: 'https://jurgisirdrakonas.lt',
    groupDomain: 'jurgisirdrakonas.lt',
    groupSubdomens: '',
  },
]

interface TestModalProps {
  isOpen?: boolean
  onClose?: (isOpen: boolean) => void
}

interface Data {
  id: string
  title: string
  groupHost: string
  groupDomain: string
  groupSubdomens: string
}

const MainContainer = styled.div`
  border-radius: 1.25rem;
  padding: 0.75rem 4.125rem 0.75rem 0.75rem;
  display: grid;
  row-gap: 0.75rem;
  position: relative;
`

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`

const TestModal = ({ isOpen, onClose }: TestModalProps) => {
  const testGroupHost = useSelector((state: RootState) => state.app.testGroupHost)

  const dispatch = useDispatch<Dispatch>()

  const { isMobile } = useMediaQuery()

  const handleClose = () => {
    onClose?.(false)
  }

  const handleChange = (data: Data) => {
    handleClose()

    dispatch.group.setGroup(null)
    dispatch.website.setWebsiteData(null)

    dispatch.app.setTestGroupHost(data.groupHost)
    dispatch.app.setTestGroupDomain(data.groupDomain)
    dispatch.app.setTestGroupSubdomens(data.groupSubdomens)
  }

  return (
    <NewModal isOpen={!!isOpen} width={isMobile ? '100%' : '40rem'} hasCustomScroll={false} onRequestClose={() => { }}>
      <MainContainer>
        {DATA.map((data) => {
          const checked = (data.id === '0' && !testGroupHost) || data.groupHost === getMainHost()

          return (
            <Checkbox key={data.id} checked={checked} onChange={() => handleChange(data)}>
              <Text type="buttonText" color="baseItemTextColor">{`${data.title}${checked && testGroupHost ? ` (${testGroupHost})` : ''
                }`}</Text>
            </Checkbox>
          )
        })}

        <CloseButtonContainer>
          <IconButton name="menu-close" onClick={handleClose} />
        </CloseButtonContainer>
      </MainContainer>
    </NewModal>
  )
}

export default TestModal
