import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import Text from './Text'
import { RootState } from 'src/utilities/store'
import { selectPriceWithCurrency } from 'src/models/profile'

interface CartButtonProps {
  totalPrice?: number
  disabled?: boolean
  ContentComponent?: ReactNode
  onCartClick?: () => void
}

const MainContainer = styled.div`
  padding: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  width: ${({ theme }) => theme.sizes.cartButtonSize}px;
  height: ${({ theme }) => theme.sizes.cartButtonSize}px;
  box-sizing: border-box;
`

const ButtonContainer = styled.button`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.firstItemColor};
  border-radius: inherit;
  box-shadow: ${({ theme }) => theme.colors.firstItemShadowColor};
  border: none;
  display: grid;
  align-content: center;
  justify-items: center;
  row-gap: 0.625rem;
  padding: 0;

  :enabled {
    cursor: pointer;
  }
`

const CartButton = (props: CartButtonProps) => {
  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, props.totalPrice))

  return (
    <MainContainer>
      <ButtonContainer disabled={props.disabled} onClick={props.onCartClick}>
        <Text type="primaryButton" color="primaryTextColor">
          {priceWithCurrency}
        </Text>
        {props.ContentComponent}
      </ButtonContainer>
    </MainContainer>
  )
}

export default memo(CartButton)
