import { memo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Text from 'src/components/Text'
import IconButton from 'src/components/IconButton'
import { useMediaQuery } from 'src/utilities/hooks'
import Location from './Location'
import { OrderPageType } from '..'

interface HeaderProps {
  hasShadow?: boolean
  locationTitle?: string
  onBackClick?: () => void
  menuPage?: OrderPageType
}

interface MainContainerProps {
  hasShadow?: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  height: 4.6875rem;
  position: relative;
  display: grid;
  background-color: ${({ theme }) => theme.colors.baseItemColor};

  ${({ hasShadow }) => !!hasShadow && `box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.07);`}

  @media ${({ theme }) => theme.queries.mobile} {
    height: auto;
    padding: 0.375rem 0;
  }
`

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.sizes.iconButtonSize}px 1fr ${({ theme }) => theme.sizes.iconButtonSize}px;
  align-items: center;
  column-gap: 1rem;
`

const LocationContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 2.5rem;
  display: grid;
`

const Header = (props: HeaderProps) => {
  const { t } = useTranslation()

  const { isMobile } = useMediaQuery()

  return (
    <MainContainer hasShadow={props.hasShadow}>
      {!!props.locationTitle && !isMobile && !props.onBackClick && (
        <LocationContainer>
          <Location locationTitle={props.locationTitle} />
        </LocationContainer>
      )}
      <HeaderContainer>
        <div>{!!props.onBackClick && <IconButton name="arrow-back" onClick={props.onBackClick} />}</div>
        <Text type={isMobile ? 'checkoutPageTitle' : 'screenTitle'} align="center" color="baseItemTextColor">
          {isMobile && props.menuPage === OrderPageType.Menu ? t('pages.checkout.components.header.title') : t('pages.checkout.components.header.titleOrder')}
        </Text>
        <div />
      </HeaderContainer>
      {!!props.locationTitle && isMobile && <Location locationTitle={props.locationTitle} />}
    </MainContainer>
  )
}

export default memo(Header)
