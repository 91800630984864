import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '.'
import api from 'src/utilities/api'
import {
  CheckInvoiceOrderContract,
  GenerateInvoiceOrderEmailContract,
  GenerateInvoiceOrderPdfContract,
  GenerateReceiptPdfContract,
  InvoiceOrderContract,
  SendReceipEmailContract,
} from 'src/types/api'

interface InvoiceState { }

const initialState: InvoiceState = {}

export const invoice = createModel<RootModel>()({
  state: initialState,
  reducers: {},
  effects: () => ({
    async checkInvoiceOrder(payload: CheckInvoiceOrderContract) {
      const res: AxiosResponse<InvoiceOrderContract> = await api.post('/qr/invoice/check', payload)

      return res.data
    },
    async sendInvoiceToEmail(payload: GenerateInvoiceOrderEmailContract) {
      await api.post('/qr/invoice/email', payload)
    },
    async getInvoiceDownloadUrl(payload: GenerateInvoiceOrderPdfContract) {
      const res: AxiosResponse<Blob> = await api.post('/qr/invoice/download', payload, { responseType: 'blob' })

      return URL.createObjectURL(res.data)
    },
    async sendReceiptToEmail(payload: SendReceipEmailContract) {
      await api.post('/qr/receipt/email', payload)
    },
    async getReceiptDownloadUrl(payload: GenerateReceiptPdfContract) {
      const res: AxiosResponse<Blob> = await api.post('/qr/receipt/download', payload, { responseType: 'blob' })

      return URL.createObjectURL(res.data)
    },
  }),
})
