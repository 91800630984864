import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import styled, { AnyStyledComponent } from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { EventListener } from 'react-lottie'

import ErrorModal from 'src/components/ErrorModal'
import { ErrorContract, OrderContract, OrderType, PaymentStatus, PaymentType, QrAvailableInvoiceOptions, ServiceFeeType } from 'src/types/api'
import { downloadFile, getServiceFeeTitle, handleError } from 'src/utilities/functions'
import { Dispatch, RootState } from 'src/utilities/store'
import Text from 'src/components/Text'
import circleSpinningAnimation from 'src/assets/lotties/circleSpinningAnimation.json'
import circleFullAnimation from 'src/assets/lotties/circleFullAnimation.json'
import checkAnimation from 'src/assets/lotties/checkAnimation.json'
import dotsAnimation from 'src/assets/lotties/dotsAnimation.json'
import Animation from 'src/components/Animation'
import PrimaryButton from 'src/components/PrimaryButton'
import { selectCartFromOrder, selectCartTakeAwayDiscountAmount, selectCartTakeAwayPrice } from 'src/models/cart'
import CartProducts from 'src/components/CartProducts'
import InfoItem from './components/InfoItem'
import AdditionalCartItem from 'src/components/AdditionalCartItem'
import IntercomButton from 'src/components/IntercomButton'
import Rating from 'src/components/Rating'
import NewModal from 'src/components/NewModal'
import { useMediaQuery } from 'src/utilities/hooks'
import CustomScroll from 'src/components/CustomScroll'
import EmailInvoiceModal from './components/EmailInvoiceModal'

interface LocationState {
  rating?: number
  tips?: number
  backgroundLocation?: Location
}

interface MainContainerProps {
  hasAppraisal?: boolean
  hasInvoiceOptions?: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxPageContainerWidth}px;
  margin: 0 auto;
  padding: ${({ hasAppraisal }) => hasAppraisal ? "4.625rem" : "0"} 0.75rem calc(2rem + ${({ hasInvoiceOptions }) => hasInvoiceOptions ? "10.75rem" : "4.625rem"}) 0.75rem;
  box-sizing: border-box;
`

const OuterAnimationContainer = styled.div`
  width: 15.875rem;
  height: 15.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
`

const InnerAnimationContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface TitleContainerMobileProps {
  hasAppraisal?: boolean
}

const TitleContainerMobile = styled.div<TitleContainerMobileProps>`
  padding: ${({ hasAppraisal }) => hasAppraisal ? "3.813rem" : "0rem"} 0 0.625rem 0;
`
const TitleContainer = styled.div`
  padding: 3.813rem 0 0.625rem 0;`
const OrderNumberContainer = styled.div`
  padding: 0.875rem 0 0.625rem 0;
`

const OrderNumber = styled(Text as unknown as AnyStyledComponent).attrs({
  color: 'baseItemTextColor',
  style: {
    fontSize: '2rem',
  },
})``

const CartProductsContainer = styled.div`
  padding-top: 2.563rem;
`

const AdditionalCartItemContainer = styled.div`
  margin-top: 0.75rem;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
  border-radius: 0.563rem;
  padding: 0 0.75rem;
`

const InfoItemContainer = styled.div`
  padding-top: 1.5rem;
  margin: 0 0.75rem 0 0.75rem;
`

const TotalPriceContainer = styled.div`
  padding-top: 2.313rem;
  margin: 0 0.75rem 0 0.75rem;
`

const NewOrderContainer = styled.div`
  padding: 0.75rem;
  display: flex;
  column-gap: 0.75rem;
  align-items: end;
  justify-content: flex-end;
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.baseItemColor};

  @media ${({ theme }) => theme.queries.mobile} {
    display: flex;
    grid-template-columns: 0px 1fr 0px;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: ${({ theme }) => theme.sizes.maxPageContainerWidth}px;
    width: 100%;
    margin: 0 auto;
  }
`

const IntercomContainer = styled.div`
    padding: 0 0 0 1.50rem;

  @media ${({ theme }) => theme.queries.mobile} {
  padding: 0 0.85rem 0 0.85rem;
  }
`

const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
`

const OrdersLinkContainer = styled.div`
  padding-top: 2.563rem;
  display: flex;
  justify-content: center;
`

const OrdersLink = styled(Link as unknown as AnyStyledComponent)`
  color: ${({ theme }) => theme.colors.baseItemTextColor};
`

const DesktopMainContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 51.5rem;
  max-height: calc(100vh - 6rem);
`

const DesktopContentMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  overflow: hidden;
`

const CustomScrollStyled = styled(CustomScroll as unknown as AnyStyledComponent).attrs({
  paddingX: '1rem',
  paddingY: '3.75rem',
})``

const DesktopContentLeftContainer = styled(CustomScrollStyled)`
  padding: 3.75rem 2.5rem;
`

const DesktopContentRightContainer = styled.div`
  overflow-y: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`

const DesktopContentRightInnerTopContainer = styled(CustomScrollStyled)`
  padding: 5.75rem 2.5rem 2.5rem 2.5rem;
`

const DesktopContentRightInnerBottomContainer = styled.div`
  padding: 2.5rem 2.5rem 3.75rem 2.5rem;
`

const DesktopDivider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.secondaryTextColor};
  opacity: 0.5;
  margin: 3.75rem 0;
`

const DesktopInfoItemsContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;
  padding-top: 2.563rem;
`

const AdditionalCartItemStyled = styled(AdditionalCartItem as unknown as AnyStyledComponent)`
  padding: 0.55rem 0;
`

const VenueTitleContainer = styled.div`
  padding-bottom: 0.625rem;
`

const VenueTitle = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'checkoutPageOptionTitle',
  align: 'center',
  color: 'baseItemTextColor',
})``

const OrderNameContainer = styled.div`
  padding-bottom: 0.625rem;
`
interface ButtonContainerProps {
  chatEnabled?: boolean
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  max-width: 100%;
  width: ${({ chatEnabled }) => chatEnabled ? "80%" : "100%"};
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Status = () => {
  const [order, setOrder] = useState<OrderContract>()
  const [errorMsg, setErrorMsg] = useState<string>()
  const [paid, setPaid] = useState<boolean>(false)
  const [animationLoopComplete, setAnimationLoopComplete] = useState<boolean>(false)
  const [showRating, setShowRating] = useState<boolean>(false)
  const [emailInvoiceModalOpen, setEmailInvoiceModalOpen] = useState<boolean>(false)
  const [receiptLoading, setReceiptLoading] = useState<boolean>(false)

  const cart = useSelector((state: RootState) => selectCartFromOrder(state, order))
  const cartTakeAwayPrice = useSelector((state: RootState) => selectCartTakeAwayPrice(state, cart))
  const cartTakeAwayDiscountAmount = useSelector((state: RootState) => selectCartTakeAwayDiscountAmount(state, cart))
  const user = useSelector((state: RootState) => state.profile.user)
  const { sessionId, backgroundLocation } = useSelector((state: RootState) => state.app)

  const loading = useSelector((state: RootState) => state.loading.effects.orders.rateOrder)

  const dispatch = useDispatch<Dispatch>()

  const { t } = useTranslation()

  const location = useLocation()
  const locationState = location.state as LocationState
  const params = useParams<{ tabletId: string; orderId: string }>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { isMobile } = useMediaQuery()

  const paymentToken = useMemo(() => searchParams.get('payment_token'), [searchParams])
  const orderToken = useMemo(() => searchParams.get('order-token'), [searchParams])

  const comment = useMemo(() => {
    const commentArr = order?.commentToKitchen?.split('|')
    commentArr?.shift()
    return commentArr?.join('|').trim()
  }, [order?.commentToKitchen])

  const eventListeners = useMemo(
    (): EventListener[] => [
      {
        eventName: 'loopComplete',
        callback: () => setAnimationLoopComplete(true),
      },
      {
        eventName: 'complete',
        callback: () => {
          if (!user?.enableAppraisal) {
            return
          }

          setTimeout(() => setShowRating(true), 1000)
        },
      },
    ],
    [user?.enableAppraisal],
  )

  const check = useCallback(async () => {
    try {
      const data = await dispatch.orders.fetchOrder({ id: params.orderId!, paymentToken, orderToken })

      // Cashier orders will be always unpaid, thus we need to skip PaymentStatus check
      if (data.paymentType === PaymentType.Cashier) {
        dispatch.cart.clearCart()
        setOrder(data)
        return
      }

      switch (data.paymentStatus) {
        case PaymentStatus.Pending:
          setTimeout(check, 5000)
          break
        case PaymentStatus.Paid:
          dispatch.cart.clearCart()
          setOrder(data)
          break
        case PaymentStatus.Unpaid:
          navigate(`/${params.tabletId}/checkout`, {
            replace: true,
            state: {
              errorMsg: t('pages.status.errorMsg'),
              backgroundLocation: !isMobile ? locationState?.backgroundLocation ?? backgroundLocation : undefined,
            },
          })
          break
        default:
          break
      }
    } catch (error) {
      const errorObj = handleError(error as AxiosError<ErrorContract>)
      if (!errorObj?.errorMsg) {
        return
      }

      setErrorMsg(errorObj.errorMsg)
    }
  }, [
    backgroundLocation,
    dispatch.cart,
    dispatch.orders,
    locationState?.backgroundLocation,
    navigate,
    orderToken,
    params.orderId,
    params.tabletId,
    paymentToken,
    t,
  ])

  const getTotalPriceDescription = useCallback(() => {
    switch (order?.paymentType) {
      case PaymentType.WebCard:
        return t('pages.status.cardPaymentTitle')
      case PaymentType.WebTransfer:
        return t('pages.status.transferPaymentTitle')
      case PaymentType.Cashier:
        return t('pages.status.cashierPaymentTitle')
      case PaymentType.ClientCode:
        return t('pages.status.clientCodePaymentTitle')
      default:
        return ''
    }
  }, [order?.paymentType, t])

  const handleRetryClick = useCallback(() => {
    setErrorMsg(undefined)

    check()
  }, [check])

  const handleBackClick = useCallback(() => {
    navigate(`/${params.tabletId}/checkout`, {
      replace: true,
      state: { backgroundLocation: locationState?.backgroundLocation },
    })
  }, [locationState?.backgroundLocation, navigate, params.tabletId])

  const handleNewOrderClick = useCallback(() => {
    navigate(`/${params.tabletId}`, { replace: true })
  }, [navigate, params.tabletId])

  const handleRate = async (rating: number) => {
    try {
      await dispatch.orders.rateOrder({ orderId: order?.id!, rating })

      navigate(location.pathname, {
        state: { rating, backgroundLocation: locationState?.backgroundLocation },
        replace: true,
      })

      if (rating < 4) {
        return
      }

      navigate(`/${params.tabletId}/appraisals`, { state: { rating, order } })
    } catch (error) {
      console.error(error)
    }
  }

  const handleDownloadClick = async (orderId?: string) => {
    if (!orderId) {
      setErrorMsg(t('pages.dynamicPage.errorModalDescription'))
      return;
    }

    try {
      setReceiptLoading(true)
      const url = await dispatch.invoice.getReceiptDownloadUrl({
        orderId: orderId
      })

      downloadFile(url, orderId)
    } catch (error) {
      console.error(error)

      setErrorMsg(t('pages.dynamicPage.errorModalDescription'))
    } finally {
      setReceiptLoading(false)
    }
  }

  const renderVenueTitle = () => {
    return (
      <VenueTitleContainer>
        <VenueTitle>{user?.restaurantDisplayTitle}</VenueTitle>
      </VenueTitleContainer>
    )
  }

  const renderOrderName = () => {
    if (!order?.name) {
      return null
    }

    return (
      <OrderNameContainer>
        <Text type="checkoutPageOptionTitle" align="center" color="baseItemTextColor">
          {order.name}
        </Text>
      </OrderNameContainer>
    )
  }

  const renderServiceFee = () => {
    if (user?.serviceFeeType === ServiceFeeType.None || !order?.serviceFeeAmount) {
      return null
    }

    return (
      <AdditionalCartItemContainer>
        <AdditionalCartItemStyled title={getServiceFeeTitle()} price={order.serviceFeeAmount} />
      </AdditionalCartItemContainer>
    )
  }

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isMobile || locationState?.backgroundLocation || !backgroundLocation) {
      return
    }

    setSearchParams((prevSearchParams) => prevSearchParams, {
      replace: true,
      state: { ...locationState, backgroundLocation },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!animationLoopComplete) {
      return
    }

    if (!order) {
      return setAnimationLoopComplete(false)
    }

    setPaid(true)
  }, [animationLoopComplete, order])

  return (
    <>
      {isMobile ? (
        <MainContainer hasAppraisal={user?.enableAppraisal} hasInvoiceOptions={user?.availableInvoiceOptions !== "none"}>
          {showRating ? (
            <RatingContainer>
              <Rating
                disabled={loading || !!locationState?.tips || !order}
                ratedRating={locationState?.rating}
                tippedAmount={locationState?.tips}
                onRate={handleRate}
              />
            </RatingContainer>
          ) : (
            <OuterAnimationContainer>
              <Animation
                options={{ animationData: paid ? circleFullAnimation : circleSpinningAnimation, loop: !paid }}
                width={user?.enableAppraisal ? "24.5rem" : "14.5rem"}
                height={user?.enableAppraisal ? "24.5rem" : "14.5rem"}
                eventListeners={eventListeners}
              />

              <InnerAnimationContainer>
                {paid ? (
                  <Animation options={{ animationData: checkAnimation, loop: false }} />
                ) : (
                  <Animation options={{ animationData: dotsAnimation }} width={user?.enableAppraisal ? "5.75rem" : "3.75rem"} height="auto" />
                )}
              </InnerAnimationContainer>
            </OuterAnimationContainer>
          )}

          {!!order && (
            <>
              <TitleContainerMobile hasAppraisal={user?.enableAppraisal}>
                {renderVenueTitle()}

                <Text type="checkoutPageTitle" align="center" color="baseItemTextColor">
                  {t('pages.status.title')}
                </Text>
              </TitleContainerMobile>

              {renderOrderName()}

              <Text type="checkoutPageOptionDescription" align="center" color="baseItemTextColor">
                {t('pages.status.orderTitle')}
              </Text>

              <OrderNumberContainer>
                <OrderNumber type="checkoutPageTitle" align="center">
                  {order?.number}
                </OrderNumber>
              </OrderNumberContainer>

              {order.paymentType === PaymentType.Cashier && (
                <Text type="productPageOptionName" align="center" color="baseItemTextColor">
                  {t('pages.status.cashText')}
                </Text>
              )}

              {!!user?.showOrderHistory && (
                <OrdersLinkContainer>
                  <OrdersLink to={`/orders/${sessionId}`}>
                    <Text type="checkoutPageButton" color="baseItemTextColor">
                      {t('pages.status.historyButtonText')}
                    </Text>
                  </OrdersLink>
                </OrdersLinkContainer>
              )}

              <CartProductsContainer>
                <CartProducts cartItems={Object.values(cart.items)} hasShadow />
              </CartProductsContainer>

              {order.type === OrderType.TakeAway && cartTakeAwayPrice > 0 && (
                <AdditionalCartItemContainer>
                  <AdditionalCartItemStyled title={t('common.takeAwayPriceTitle')} price={cartTakeAwayPrice} />
                </AdditionalCartItemContainer>
              )}

              {order.type === OrderType.TakeAway && cartTakeAwayDiscountAmount > 0 && (
                <AdditionalCartItemContainer>
                  <AdditionalCartItemStyled
                    title={t('common.takeAwayDiscountAmountTitle')}
                    price={cartTakeAwayDiscountAmount}
                    isDiscount
                  />
                </AdditionalCartItemContainer>
              )}

              {!!order.promoCodeDiscount && order.promoCodeDiscount > 0 && (
                <AdditionalCartItemContainer>
                  <AdditionalCartItemStyled title={t('common.discount')} price={order.promoCodeDiscount} isDiscount />
                </AdditionalCartItemContainer>
              )}

              {!!order.loyaltyPaidAmount && order.loyaltyPaidAmount > 0 && (
                <AdditionalCartItemContainer>
                  <AdditionalCartItemStyled title={t('common.discount')} price={order.loyaltyPaidAmount} isDiscount />
                </AdditionalCartItemContainer>
              )}

              {!!order.tipAmount && (
                <AdditionalCartItemContainer>
                  <AdditionalCartItemStyled title={t('common.tips')} price={order.tipAmount} />
                </AdditionalCartItemContainer>
              )}

              {renderServiceFee()}

              {!!comment && (
                <InfoItemContainer>
                  <InfoItem title={t('common.commentTitle')} description={comment} />
                </InfoItemContainer>
              )}

              {!!order.promoCode && (
                <InfoItemContainer>
                  <InfoItem title={t('common.discountTitle')} description={order.promoCode} />
                </InfoItemContainer>
              )}

              {!!order.loyaltyCode && (
                <InfoItemContainer>
                  <InfoItem title={t('common.discountTitle')} description={order.loyaltyCode} />
                </InfoItemContainer>
              )}

              <TotalPriceContainer>
                <InfoItem
                  title={t('pages.status.totalPriceTitle')}
                  description={getTotalPriceDescription()}
                  price={order.totalPrice}
                />
              </TotalPriceContainer>

              <NewOrderContainer>

                {user?.enableSupportChat ? <IntercomContainer><IntercomButton />  </IntercomContainer> : <div />}


                <ButtonContainer chatEnabled={user?.enableSupportChat}>
                  <PrimaryButton color={user?.availableInvoiceOptions !== QrAvailableInvoiceOptions.None ? 'secondItemColor' : 'firstItemColor'} onClick={handleNewOrderClick}>
                    {t('pages.status.newOrderButtonText')}
                  </PrimaryButton>

                  {order?.paymentType !== PaymentType.Cashier && user?.availableInvoiceOptions === QrAvailableInvoiceOptions.EmailAndDownload ?
                    <><div style={{ marginTop: 12 }}></div>
                      <PrimaryButton disabled={receiptLoading} color="secondItemColor" onClick={() => setEmailInvoiceModalOpen(true)}>
                        {t('pages.status.sendButtonLabel')}
                      </PrimaryButton>
                      <div style={{ marginTop: 12 }}></div>
                      <PrimaryButton onClick={() => handleDownloadClick(order?.id)}>
                        {t('pages.status.downloadButtonLabel')}
                      </PrimaryButton>
                    </> : <div />}

                  {order?.paymentType !== PaymentType.Cashier && user?.availableInvoiceOptions === QrAvailableInvoiceOptions.EmailOnly ?
                    <><div style={{ marginTop: 12 }}></div>
                      <PrimaryButton disabled={receiptLoading} onClick={() => setEmailInvoiceModalOpen(true)}>
                        {t('pages.status.sendButtonLabel')}
                      </PrimaryButton></> : <div />}

                  {order?.paymentType !== PaymentType.Cashier && user?.availableInvoiceOptions === QrAvailableInvoiceOptions.DownloadOnly ?
                    <><div style={{ marginTop: 12 }}></div>
                      <PrimaryButton onClick={() => handleDownloadClick(order?.id)}>
                        {t('pages.status.downloadButtonLabel')}
                      </PrimaryButton></> : <div />}

                </ButtonContainer>


                <div />
              </NewOrderContainer>
            </>
          )}
        </MainContainer >
      ) : (
        <NewModal isOpen width="57.563rem" height="51.5rem" showCloseButton onRequestClose={handleNewOrderClick}>
          <DesktopMainContainer>
            <DesktopContentMainContainer>
              <DesktopContentLeftContainer>
                {showRating ? (
                  <RatingContainer>
                    <Rating
                      disabled={loading || !!locationState?.tips || !order}
                      ratedRating={locationState?.rating}
                      tippedAmount={locationState?.tips}
                      onRate={handleRate}
                    />
                  </RatingContainer>
                ) : (
                  <OuterAnimationContainer>
                    <Animation
                      options={{ animationData: paid ? circleFullAnimation : circleSpinningAnimation, loop: !paid }}
                      width="24.5rem"
                      height="24.5rem"
                      eventListeners={eventListeners}
                    />

                    <InnerAnimationContainer>
                      {paid ? (
                        <Animation options={{ animationData: checkAnimation, loop: false }} />
                      ) : (
                        <Animation options={{ animationData: dotsAnimation }} width="5.75rem" height="auto" />
                      )}
                    </InnerAnimationContainer>
                  </OuterAnimationContainer>
                )}

                {!!order && (
                  <>
                    <TitleContainer>
                      {renderVenueTitle()}

                      <Text type="checkoutPageTitle" align="center" color="baseItemTextColor">
                        {t('pages.status.title')}
                      </Text>
                    </TitleContainer>

                    {renderOrderName()}

                    <Text type="checkoutPageOptionDescription" align="center" color="baseItemTextColor">
                      {t('pages.status.orderTitle')}
                    </Text>

                    <OrderNumberContainer>
                      <OrderNumber type="checkoutPageTitle" align="center">
                        {order.number}
                      </OrderNumber>
                    </OrderNumberContainer>

                    {order.paymentType === PaymentType.Cashier && (
                      <Text type="productPageOptionName" align="center" color="baseItemTextColor">
                        {t('pages.status.cashText')}
                      </Text>
                    )}

                    {!!user?.showOrderHistory && (
                      <OrdersLinkContainer>
                        <OrdersLink to={`/orders/${sessionId}`}>
                          <Text type="checkoutPageButton" color="baseItemTextColor">
                            {t('pages.status.historyButtonText')}
                          </Text>
                        </OrdersLink>
                      </OrdersLinkContainer>
                    )}

                    <DesktopInfoItemsContainer>
                      <InfoItem
                        title={t('pages.status.orderType.title')}
                        description={
                          order.type === OrderType.TakeAway
                            ? t('pages.status.orderType.takeAway')
                            : t('pages.status.orderType.table')
                        }
                      />

                      <InfoItem title={t('pages.status.paymentTypeTitle')} description={getTotalPriceDescription()} />

                      {!!comment && <InfoItem title={t('common.commentTitle')} description={comment} />}

                      {!!order.promoCode && (
                        <InfoItem title={t('common.discountTitle')} description={order.promoCode} />
                      )}

                      {!!order.loyaltyCode && (
                        <InfoItem title={t('common.discountTitle')} description={order.loyaltyCode} />
                      )}
                    </DesktopInfoItemsContainer>
                  </>
                )}
              </DesktopContentLeftContainer>

              <DesktopDivider />

              <DesktopContentRightContainer>
                {!!order && (
                  <>
                    <DesktopContentRightInnerTopContainer>
                      <CartProducts cartItems={Object.values(cart.items)} hasShadow />

                      {order.type === OrderType.TakeAway && cartTakeAwayPrice > 0 && (
                        <AdditionalCartItemContainer>
                          <AdditionalCartItemStyled title={t('common.takeAwayPriceTitle')} price={cartTakeAwayPrice} />
                        </AdditionalCartItemContainer>
                      )}

                      {order.type === OrderType.TakeAway && cartTakeAwayDiscountAmount > 0 && (
                        <AdditionalCartItemContainer>
                          <AdditionalCartItemStyled
                            title={t('common.takeAwayDiscountAmountTitle')}
                            price={cartTakeAwayDiscountAmount}
                            isDiscount
                          />
                        </AdditionalCartItemContainer>
                      )}

                      {!!order.promoCodeDiscount && order.promoCodeDiscount > 0 && (
                        <AdditionalCartItemContainer>
                          <AdditionalCartItemStyled title={t('common.discount')} price={order.promoCodeDiscount} isDiscount />
                        </AdditionalCartItemContainer>
                      )}

                      {!!order.loyaltyPaidAmount && order.loyaltyPaidAmount > 0 && (
                        <AdditionalCartItemContainer>
                          <AdditionalCartItemStyled title={t('common.discount')} price={order.loyaltyPaidAmount} isDiscount />
                        </AdditionalCartItemContainer>
                      )}

                      {!!order.tipAmount && (
                        <AdditionalCartItemContainer>
                          <AdditionalCartItemStyled title={t('common.tips')} price={order.tipAmount} />
                        </AdditionalCartItemContainer>
                      )}

                      {renderServiceFee()}
                    </DesktopContentRightInnerTopContainer>

                    <DesktopContentRightInnerBottomContainer>
                      <InfoItem title={t('pages.status.totalPriceTitle')} price={order.totalPrice} />
                    </DesktopContentRightInnerBottomContainer>
                  </>
                )}
              </DesktopContentRightContainer>
            </DesktopContentMainContainer>

            {!!order && (
              <NewOrderContainer>
                {user?.enableSupportChat && <IntercomContainer><IntercomButton /></IntercomContainer>}

                <PrimaryButton maxWidth="13.5rem" onClick={handleNewOrderClick} color={user?.availableInvoiceOptions !== QrAvailableInvoiceOptions.None ? 'secondItemColor' : 'firstItemColor'}>
                  {t('pages.status.newOrderButtonText')}
                </PrimaryButton>

                {order?.paymentType !== PaymentType.Cashier && user?.availableInvoiceOptions === QrAvailableInvoiceOptions.EmailAndDownload &&
                  <> <PrimaryButton maxWidth="13.5rem" color="secondItemColor" disabled={receiptLoading} onClick={() => setEmailInvoiceModalOpen(true)}>
                    {t('pages.status.sendButtonLabel')}
                  </PrimaryButton>
                    <PrimaryButton maxWidth="13.5rem" onClick={() => handleDownloadClick(order?.id)}>
                      {t("pages.status.downloadButtonLabel")}
                    </PrimaryButton>
                  </>}

                {order?.paymentType !== PaymentType.Cashier && user?.availableInvoiceOptions === QrAvailableInvoiceOptions.EmailOnly &&

                  <PrimaryButton maxWidth="13.5rem" disabled={receiptLoading} onClick={() => setEmailInvoiceModalOpen(true)}>
                    {t("pages.status.sendButtonLabel")}
                  </PrimaryButton>}

                {order?.paymentType !== PaymentType.Cashier && user?.availableInvoiceOptions === QrAvailableInvoiceOptions.DownloadOnly &&
                  <>
                    <PrimaryButton maxWidth="13.5rem" onClick={() => handleDownloadClick(order?.id)}>
                      {t("pages.status.downloadButtonLabel")}
                    </PrimaryButton></>}
              </NewOrderContainer>
            )}
          </DesktopMainContainer>
        </NewModal>
      )}
      <EmailInvoiceModal isOpen={emailInvoiceModalOpen} orderId={order?.id} onClose={() => setEmailInvoiceModalOpen(false)} />
      <ErrorModal errorMsg={errorMsg} onRetryClick={handleRetryClick} onBackClick={handleBackClick} />
    </>
  )
}

export default Status
