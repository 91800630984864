import axios from 'axios'

import { store } from './store'
import { getTabletId } from './functions'

const api = axios.create({
  baseURL: process.env.VITE_APP_API_URL,
})

api.interceptors.request.use((config) => {
  const tabletId = getTabletId() ?? ''
  const sessionId = store.getState().app.sessionId!
  const language = store.getState().app.languageCode

  config.headers.Authorization = `Bearer ${process.env.VITE_APP_API_TOKEN}`
  config.headers.TabletId = tabletId
  config.headers.SessionId = sessionId
  config.headers.Languages = language
  config.headers.WebshopLocationUrl = window.location.href

  return config
})

export default api
