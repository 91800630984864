import { memo, MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'

import Text from './Text'
import { Color } from 'src/utilities/theme'

interface PrimaryButtonProps {
  width?: string
  maxWidth?: string
  disabled?: boolean
  color?: Color
  borderRadius?: string
  children?: ReactNode
  type?: 'button' | 'submit' | 'reset'
  textTypeSmall?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

interface ButtonProps {
  width?: string
  maxWidth?: string
  color?: Color
  borderRadius?: string
}

const Button = styled.button<ButtonProps>`
  padding-right: 16px;
  padding-left: 16px;
  border: none;
  cursor: pointer;
  border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : "34rem"};
  box-shadow:  ${({ color, theme }) => color ? "" : theme.colors.firstItemShadowColor};
  background-color: ${({ theme, color }) => color ? theme.colors[color] : theme.colors.firstItemColor};
  width: ${({ width }) => width ?? '100%'};
  height: 2.75rem;
  max-width: ${({ maxWidth }) => maxWidth ?? 'none'};
`

const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <Button
      type={props.type}
      width={props.width}
      maxWidth={props.maxWidth}
      disabled={props.disabled}
      onClick={props.onClick}
      color={props.color}
      borderRadius={props.borderRadius}
    >
      <Text type={props.textTypeSmall ? "productPageOptionDescription" : "productPageOptionName"} align="center" color="primaryTextColor">
        {props.children}
      </Text>
    </Button>
  )
}

export default memo(PrimaryButton)
