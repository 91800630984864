import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { AnyStyledComponent } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isAxiosError } from 'axios'
import { Trans } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'

import Footer from 'src/components/Footer'
import { Dispatch, RootState } from 'src/utilities/store'
import Text from 'src/components/Text'
import Header from './components/Header'
import CartProducts, { CartProductsRef } from 'src/components/CartProducts'
import Popover from 'src/components/Popover'
import {
  selectCart,
  selectCartProductsWithAgeToComply,
  selectCartTakeAwayDiscountAmount,
  selectCartTakeAwayPrice,
  selectCartTipsAmount,
  selectCartTotalPrice,
  selectCartVisibleTotalPrice,
  selectHasRestrictedItemsInCart,
  selectIsCartEmpty,
} from 'src/models/cart'
import {
  CompanyInformationContract,
  ErrorContract,
  InputFieldType,
  OrderType,
  PaymentType,
  QrDefaultOrderType,
  ServiceFeeType,
} from 'src/types/api'
import AddInfoItem from './components/AddInfoItem'
import CommentIcon from 'src/assets/icons/comment.svg?react'
import DiscountIcon from 'src/assets/icons/discount.svg?react'
import ErrorModal, { ErrorModalProps } from 'src/components/ErrorModal'
import PaymentSelectModal from 'src/components/PaymentSelectModal'
import { getCartTotalPrice, getServiceFeeTitle, getUnixTimestamp, handleError, isEmailValid } from 'src/utilities/functions'
import AdditionalCartItem from 'src/components/AdditionalCartItem'
import ConfirmAgeModal from 'src/components/ConfirmAgeModal'
import ClientCodeModal from './components/ClientCodeModal'
import PagerNumberModal from './components/PagerNumberModal'
import GuestIdentificationModal from './components/GuestIdentificationModal'
import IconActionButton from 'src/components/IconActionButton'
import BagIcon from 'src/assets/icons/bag.svg'
import PlateIcon from 'src/assets/icons/plate.svg'
import ClockIcon from 'src/assets/images/clock.png'
import EmailIcon from 'src/assets/icons/email.svg?react'
import ReceiptIcon from 'src/assets/icons/receipt.svg?react'
import { useMediaQuery, useTranslations } from 'src/utilities/hooks'
import CardInput, { CardInputRef } from 'src/components/CardInput'
import NewModal from 'src/components/NewModal'
import CustomScroll from 'src/components/CustomScroll'
import { TIME_FORMAT, dayjs } from 'src/utilities/dayjs'
import PhoneNumberModal from 'src/components/PhoneNumberModal'
import EmailAndCompanyInfoCardInput from 'src/components/EmailAndCompanyInfoCardInput'
import { selectAcceptsAllOrderTypes, selectHasNoConfigurationSettings, selectPriceWithCurrency } from 'src/models/profile'
import TipsCard from 'src/components/TipsCard'
import TipsImg from 'src/assets/images/tips.png'
import OrderLoadingModal from './components/OrderLoadingModal'
import ChooseTimeButton from './components/ChooseTimeButton'
import ExpiredSessionModal from './components/ExpiredSessionModal'
import OptionButton from '../DeliveryTime/components/OptionButton'
import RestrictedItemModal from 'src/components/RestrictedItemModal'

const DESKTOP_CONTENT_CONTAINER_PADDING = 40 as const
const MOBILE_CONTENT_CONTAINER_PADDING_Y = 10 as const
const CART_PRODUCTS_GAP = 12 as const
const DESKTOP_INFO_ITEMS_CONTAINER_GAP = 16 as const
const ADD_INFO_ITEMS_CONTAINER_GAP = 24 as const

interface LocationState {
  errorMsg?: string
  backgroundLocation?: Location
  fromDiscount?: boolean
}

export enum OrderPageType {
  Menu = 'menu',
  Order = 'order'
}

const MainContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxPageContainerWidth}px;
  padding-bottom: calc(3.625rem + ${({ theme }) => theme.sizes.cartButtonSize / 2}px);
`

const HeaderContainer = styled.div`
  padding: 0 0.75rem;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  z-index: 1;
`

const ContentContainer = styled.div`
  padding: ${MOBILE_CONTENT_CONTAINER_PADDING_Y}px 0.75rem;
`

const DeliveryButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 1rem;
  column-gap: 1rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding-bottom: 12px;
    column-gap: 0.75rem;
  }
`

const ChooseTimeButtonContainer = styled.div`
  padding-bottom: 1rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding-bottom: 16px;
  }
`

const AddInfoItemsContainer = styled.div`
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: grid;
  row-gap: ${ADD_INFO_ITEMS_CONTAINER_GAP}px;
`
type AdditionalCartItemContainerProps = {
  biggerpadding?: boolean;
}

const AdditionalCartItemContainer = styled.div<AdditionalCartItemContainerProps>`
  padding-top: ${({ biggerpadding }) => biggerpadding ? 0.75 : 0.05}rem;
`

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxPageContainerWidth}px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`

const TermsContainer = styled.div`
  padding: 0.5rem calc(${({ theme }) => theme.sizes.cartButtonSize}px + 1rem) 0.5rem 0.75rem;
  box-shadow: 0 0 0.875rem rgba(0, 0, 0, 0.043679);
  border-radius: 0.563rem 0.563rem 0 0;
`

const Terms = styled(Text as unknown as AnyStyledComponent).attrs({
  color: 'secondaryTextColor',
  type: 'productPageOptionDescription',
})``

const TermsLink = styled.a.attrs({
  target: '_blank',
})`
  color: inherit;
`

const DesktopMainContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 55.938rem;
  max-height: calc(100vh - 6rem);
`

const DesktopContentMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  overflow: hidden;
`

const CustomScrollStyled = styled(CustomScroll as unknown as AnyStyledComponent).attrs({
  paddingX: '1rem',
  paddingY: '2.5rem',
})``

const DesktopContentContainer = styled(CustomScrollStyled)`
  padding: ${DESKTOP_CONTENT_CONTAINER_PADDING}px;
`

const DesktopContentRightContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
`

const DesktopContentRightTopContainer = styled(CustomScrollStyled)`
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
`

const DesktopContentRightBottomContainer = styled.div`
  min-height: 2.875rem;
  padding: 1rem 7rem 1rem 2.5rem;
  box-sizing: border-box;
`

const DesktopOrderTypeTitleContainer = styled.div`
  padding-bottom: 16px;
`

const DesktopDivider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.secondaryTextColor};
  opacity: 0.5;
  margin: 2.5rem 0;
`

const DesktopInfoItemsContainer = styled.div`
  display: grid;
  row-gap: ${DESKTOP_INFO_ITEMS_CONTAINER_GAP}px;
`
const OptionButtonContainer = styled.div`
  margin-bottom: 0.5rem;
  border-radius: 34px;
`

const Checkout = () => {
  const [error, setError] = useState<ErrorModalProps | null>(null)
  const [paymentSelectModalOpen, setPaymentSelectModalOpen] = useState<boolean>(false)
  const [confirmAgeModalOpen, setConfirmAgeModalOpen] = useState<boolean>(false)
  const [clientCodeModalOpen, setClientCodeModalOpen] = useState<boolean>(false)
  const [pagerNumberModalOpen, setPagerNumberModalOpen] = useState<boolean>(false)
  const [guestIdentificationModalOpen, setGuestIdentificationModalOpen] = useState<boolean>(false)
  const [phoneNumberModalOpen, setPhoneNumberModalOpen] = useState<boolean>(false)
  const [orderLoadingModalOpen, setOrderLoadingModalOpen] = useState<boolean>(false)
  const [expiredSessionModalOpen, setExpiredSessionModalOpen] = useState<boolean>(false)
  const [restrictedItemModalOpen, setRestrictedModalOpen] = useState<boolean>(false)
  const [ageConfirmed, setAgeConfirmed] = useState<boolean>(false)
  const [showAgeErrors, setShowAgeErrors] = useState<boolean>(false)
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>()
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [promoCodeLoading, setPromoCodeLoading] = useState<boolean>(false)
  const [orderPage, setOrderPage] = useState<OrderPageType>(OrderPageType.Menu)

  const cart = useSelector(selectCart)
  const { orderPrice, loyaltyCode } = useSelector((state: RootState) => state.orders)
  const cartTotalPrice = useSelector(selectCartTotalPrice)
  const cartVisibleTotalPrice = useSelector(selectCartVisibleTotalPrice)
  const cartTakeAwayPrice = useSelector((state: RootState) => selectCartTakeAwayPrice(state))
  const cartTakeAwayDiscountAmount = useSelector(selectCartTakeAwayDiscountAmount)
  const user = useSelector((state: RootState) => state.profile.user)

  const cartProductsWithAgeToComply = useSelector(selectCartProductsWithAgeToComply)
  const { selectedTimeSlot, soonAsPossible, reservedTimeSlot } = useSelector((state: RootState) => state.timeSlots)
  const hasRestrictedItemsInCart = useSelector(selectHasRestrictedItemsInCart)
  const acceptsAllOrderTypes = useSelector(selectAcceptsAllOrderTypes)
  const promoCodeDiscountWithCurrency = useSelector((state: RootState) =>
    selectPriceWithCurrency(state, orderPrice?.promoCodeDiscount),
  )
  const availableLoyaltyAmountWithCurrency = useSelector((state: RootState) =>
    selectPriceWithCurrency(state, orderPrice?.availableLoyaltyAmount),
  )
  const cartTipsAmount = useSelector(selectCartTipsAmount)
  const websiteData = useSelector((state: RootState) => state.website.websiteData)
  const isCartEmpty = useSelector(selectIsCartEmpty)

  const createOrderLoading = useSelector((state: RootState) => state.loading.effects.orders.createOrder)
  const checkLoyaltyCodeLoading = useSelector((state: RootState) => state.loading.effects.orders.checkLoyaltyCode)

  const oneCheckout = useSelector(selectHasNoConfigurationSettings)

  const desktopContentContainerRef = useRef<OverlayScrollbarsComponentRef>(null)
  const desktopContentRightTopContainerRef = useRef<OverlayScrollbarsComponentRef>(null)
  const promoCodeCardInputRef = useRef<CardInputRef>(null)
  const cartProductsRef = useRef<CartProductsRef>(null)
  const headerContainerRef = useRef<HTMLDivElement>(null)
  const emailAndCompanyInfoCardInputRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const { update } = useIntercom()

  const navigate = useNavigate()
  const translations = useTranslations()
  const location = useLocation()
  const locationState = location.state as LocationState | null
  const params = useParams<{ tabletId: string }>()

  const dispatch = useDispatch<Dispatch>()

  const { isMobile } = useMediaQuery()

  const errors = useMemo(() => {
    const isCompany = !!cart.isCompany

    const orderType = !cart.orderType || cart.orderType === OrderType.None
    const timeSlot = !selectedTimeSlot && !soonAsPossible && !!user?.enableTimeSlots
    const invoiceEmail = cart.invoiceEmail
      ? !isEmailValid(cart.invoiceEmail)
      : user?.qrInvoiceEmailInputType === InputFieldType.Required
    const pagerNumber = !cart.pagerNumber && !!user?.requiredUserInput && !isMobile
    const restrictedItems = hasRestrictedItemsInCart && cart.orderType === OrderType.TakeAway
    const companyInfo: Record<keyof CompanyInformationContract, boolean> = {
      name: isCompany && !cart.companyInfo?.name,
      code: isCompany && !cart.companyInfo?.code,
      vatCode: false,
      address: isCompany && !cart.companyInfo?.address,
    }
    const tips = user?.tipsInputType === InputFieldType.Required && !cart.tipsData

    const isErrors =
      orderType ||
      timeSlot ||
      invoiceEmail ||
      pagerNumber ||
      restrictedItems ||
      Object.values(companyInfo).some((value) => value) ||
      tips

    return { orderType, timeSlot, invoiceEmail, pagerNumber, isErrors, restrictedItems, companyInfo, tips }
  }, [
    cart.companyInfo?.address,
    cart.companyInfo?.code,
    cart.companyInfo?.name,
    cart.invoiceEmail,
    cart.isCompany,
    cart.orderType,
    cart.pagerNumber,
    cart.tipsData,
    hasRestrictedItemsInCart,
    isMobile,
    selectedTimeSlot,
    soonAsPossible,
    user?.enableTimeSlots,
    user?.qrInvoiceEmailInputType,
    user?.requiredUserInput,
    user?.tipsInputType,
  ])

  const loading = useMemo(() => {
    return createOrderLoading || checkLoyaltyCodeLoading
  }, [checkLoyaltyCodeLoading, createOrderLoading])

  const deliveryTime = selectedTimeSlot
    ? `(${dayjs.utc(selectedTimeSlot.date).format(TIME_FORMAT)})`
    : ''

  const restrictedErrorMsg = useMemo(() => {
    if (!errors.restrictedItems) {
      return
    }

    return t('pages.checkout.restrictedErrorMsg')
  }, [errors.restrictedItems, t])

  const calculateOrderPrice = useCallback(
    async (data?: { promoCode?: string; loyaltyCode?: string }) => {
      if (isCartEmpty) {
        return
      }

      try {
        return await dispatch.orders.calculateOrderPrice({
          userVisiblePrice: cartTotalPrice,
          promoCode: data?.promoCode ?? cart.promoCode,
          loyaltyCode: data?.loyaltyCode,
        })
      } catch (error) {
        console.error(error)
      }
    },
    [cart.promoCode, isCartEmpty, cartTotalPrice, dispatch.orders],
  )

  const clearError = useCallback(() => {
    setError(null)
    if (locationState?.errorMsg) {
      const { errorMsg, ...state } = locationState
      navigate(`/${params.tabletId}/checkout`, { replace: true, state })
    }
  }, [locationState?.errorMsg])

  const togglePaymentSelectModal = useCallback(() => {
    setPaymentSelectModalOpen((prevPaymentSelectModalOpen) => !prevPaymentSelectModalOpen)
  }, [])

  const toggleConfirmAgeModal = useCallback(() => {
    setConfirmAgeModalOpen((prevConfirmAgeModalOpen) => !prevConfirmAgeModalOpen)
  }, [])

  const toggleClientCodeModal = useCallback(() => {
    setClientCodeModalOpen((prevClientCodeModalOpen) => !prevClientCodeModalOpen)
  }, [])

  const togglePagerNumberModal = useCallback(() => {
    setPagerNumberModalOpen((prevPagerNumberModalOpen) => !prevPagerNumberModalOpen)
  }, [])

  const toggleGuestIdentificationModal = useCallback(() => {
    setGuestIdentificationModalOpen((prevGuestIdentificationModalOpen) => !prevGuestIdentificationModalOpen)
  }, [])

  const togglePhoneNumberModal = useCallback(() => {
    setPhoneNumberModalOpen((prevPhoneNumberModalOpen) => !prevPhoneNumberModalOpen)
  }, [])

  const toggleOrderLoadingModal = useCallback(() => {
    setOrderLoadingModalOpen(false)
  }, [])

  const getAndSetError = (error: unknown) => {
    if (!isAxiosError<ErrorContract>(error)) {
      return
    }

    setError(handleError(error))
  }

  const createOrder = useCallback(
    async (data: {
      paymentType: PaymentType
      clientCode?: number
      loyaltyCode?: string
      isUserAgeConfirmed?: boolean
    }) => {
      try {
        const orderData = await dispatch.orders.createOrder({
          cart,
          userVisiblePrice: cartTotalPrice,
          promoCode: orderPrice?.promoCode ?? '',
          paymentType: data.paymentType,
          isUserAgeConfirmed: data.isUserAgeConfirmed ?? ageConfirmed,
          clientCode: data.clientCode,
          loyaltyCode: data.loyaltyCode,
        })

        update({
          customAttributes: {
            last_order_at: getUnixTimestamp(orderData?.startTimeStamp!),
            last_order_number: orderData?.number,
            last_order_id: orderData?.id,
          },
        })

        return orderData
      } catch (error) {
        getAndSetError(error)
      }
    },
    [ageConfirmed, cart, cartTotalPrice, dispatch.orders, orderPrice?.promoCode, update],
  )

  const navigateToStatus = useCallback(
    (orderId: string) => {
      if (isMobile) {
        navigate(`/${params.tabletId}/${orderId}/status`)
        return
      }

      navigate(`/${params.tabletId}/${orderId}/status`, {
        state: { backgroundLocation: locationState?.backgroundLocation },
      })
    },
    [isMobile, locationState?.backgroundLocation, navigate, params.tabletId],
  )

  const createLoyaltyOrder = useCallback(
    async (isUserAgeConfirmed?: boolean) => {
      if (!loyaltyCode?.loyaltyCode || loyaltyCode.clientCode || loyaltyCode.additionalPaymentRequired) {
        return
      }

      toggleOrderLoadingModal()

      const data = await createOrder({
        paymentType: PaymentType.ClientCode,
        loyaltyCode: loyaltyCode.loyaltyCode,
        isUserAgeConfirmed,
      })

      toggleOrderLoadingModal()

      if (!data) {
        return
      }

      navigateToStatus(data.id!)

      return true
    },
    [
      createOrder,
      loyaltyCode?.additionalPaymentRequired,
      loyaltyCode?.clientCode,
      loyaltyCode?.loyaltyCode,
      navigateToStatus,
      toggleOrderLoadingModal,
    ],
  )

  const checkLoyaltyCode = useCallback(
    async (code: string) => {
      const data = await dispatch.orders.checkLoyaltyCode({
        loyaltyCode: code,
        userVisiblePrice: cartTotalPrice,
      })

      await calculateOrderPrice({ loyaltyCode: data.loyaltyCode! })

      return data
    },
    [calculateOrderPrice, cartTotalPrice, dispatch.orders],
  )

  const navigateToDeliveryTime = () => {
    if (isMobile) {
      navigate('delivery-time', { state: { backgroundLocation: location } })
      return
    }

    navigate('delivery-time', { state: { backgroundLocation: locationState?.backgroundLocation } })
  }

  const getMobileHeaderOffsetHeight = useCallback(() => {
    return headerContainerRef.current?.offsetHeight
  }, [])

  const scrollDesktopContentContainer = useCallback((top: number = 0) => {
    desktopContentContainerRef.current?.osInstance()?.elements()?.viewport?.scroll({ top, behavior: 'smooth' })
  }, [])

  const scrollWindow = useCallback((top: number = 0) => {
    window.scroll({ top, behavior: 'smooth' })
  }, [])

  const scrollToPromoCode = useCallback(() => {
    if (isMobile) {
      const scrollTop = document.body.scrollHeight - window.innerHeight

      scrollWindow(scrollTop)

      return
    }

    const scrollHeight = desktopContentContainerRef.current?.osInstance()?.elements().viewport.scrollHeight ?? 0
    const clientHeight = desktopContentContainerRef.current?.osInstance()?.elements().viewport.clientHeight ?? 0

    const scrollTop = scrollHeight - clientHeight

    scrollDesktopContentContainer(scrollTop)
  }, [isMobile, scrollDesktopContentContainer, scrollWindow])

  const scrollToTop = useCallback(() => {
    if (isMobile) {
      scrollWindow(-100)
      return
    }

    scrollDesktopContentContainer()
  }, [isMobile, scrollDesktopContentContainer, scrollWindow])

  const calculateOrderPriceAndCheckLoyaltyCode = useCallback(async () => {
    try {
      const promises: any[] = [calculateOrderPrice()]

      if (loyaltyCode?.loyaltyCode) {
        promises.push(
          dispatch.orders.checkLoyaltyCode({
            loyaltyCode: loyaltyCode.loyaltyCode,
            userVisiblePrice: cartTotalPrice,
          }),
        )
      }

      await Promise.all(promises)
    } catch (error) {
      console.error(error)
    }
  }, [calculateOrderPrice, cartTotalPrice, dispatch.orders, loyaltyCode?.loyaltyCode])

  const handleBackClick = useCallback(() => {
    if (isMobile && orderPage === OrderPageType.Order) {
      setOrderPage(OrderPageType.Menu)
    } else {
      navigate(`/${params.tabletId}`, { replace: true })
    }
  }, [navigate, params.tabletId, orderPage])

  const handleErrors = useCallback(() => {
    if (errors.orderType || errors.timeSlot) {
      scrollToTop()
      return
    }

    if (errors.invoiceEmail) {
      const offsetTop = emailAndCompanyInfoCardInputRef.current?.offsetTop
      if (typeof offsetTop === 'undefined') {
        return
      }

      if (isMobile) {
        const offsetHeight = getMobileHeaderOffsetHeight()
        if (typeof offsetHeight !== 'number') {
          return
        }

        scrollWindow(offsetTop - offsetHeight - ADD_INFO_ITEMS_CONTAINER_GAP)

        return
      }

      scrollDesktopContentContainer(offsetTop - DESKTOP_INFO_ITEMS_CONTAINER_GAP)

      return
    }

    if (errors.restrictedItems) {
      const firstRestrictedProductOffsetTop = cartProductsRef.current?.getFirstRestrictedProductOffsetTop()
      if (typeof firstRestrictedProductOffsetTop !== 'number') {
        return
      }

      if (isMobile) {
        const offsetHeight = getMobileHeaderOffsetHeight()
        if (typeof offsetHeight !== 'number') {
          return
        }

        scrollWindow(firstRestrictedProductOffsetTop - offsetHeight - CART_PRODUCTS_GAP)

        return
      }

      desktopContentRightTopContainerRef.current
        ?.osInstance()
        ?.elements()
        .viewport.scroll({ top: firstRestrictedProductOffsetTop - CART_PRODUCTS_GAP, behavior: 'smooth' })
    }
  }, [
    errors.invoiceEmail,
    errors.orderType,
    errors.restrictedItems,
    errors.timeSlot,
    getMobileHeaderOffsetHeight,
    isMobile,
    scrollDesktopContentContainer,
    scrollToTop,
    scrollWindow,
  ])

  const handleCartClick = useCallback(async () => {

    if (isMobile && orderPage === OrderPageType.Menu && !oneCheckout) {
      setOrderPage(OrderPageType.Order);
      return;
    }

    if (isMobile && orderPage === OrderPageType.Order && hasRestrictedItemsInCart && cart?.orderType === OrderType.TakeAway && !oneCheckout) {
      setRestrictedModalOpen(true);
    }

    if (errors.isErrors) {
      setShowErrors(true)
      handleErrors()
      return
    }

    if (Object.keys(cartProductsWithAgeToComply).length) {
      toggleConfirmAgeModal()
      return
    }

    setAgeConfirmed(true)

    if (user?.requiredUserInput && isMobile) {
      togglePagerNumberModal()
      return
    }

    if (user?.phoneNumberInputType !== InputFieldType.None) {
      togglePhoneNumberModal()
      return
    }

    if (user?.guestIdentificationInputType !== InputFieldType.None) {
      toggleGuestIdentificationModal()
      return
    }

    if (await createLoyaltyOrder(true)) {
      return
    }

    togglePaymentSelectModal()
  }, [
    cartProductsWithAgeToComply,
    createLoyaltyOrder,
    errors.isErrors,
    handleErrors,
    isMobile,
    toggleConfirmAgeModal,
    toggleGuestIdentificationModal,
    togglePagerNumberModal,
    togglePaymentSelectModal,
    togglePhoneNumberModal,
    user?.guestIdentificationInputType,
    user?.phoneNumberInputType,
    user?.requiredUserInput,
    orderPage,
    setPhoneNumberModalOpen
  ])

  const handleAdd = useCallback(
    (id: string) => {
      dispatch.cart.increaseProductCount(id)
    },
    [dispatch.cart],
  )

  const handleRemove = useCallback(
    (id: string) => {
      if (cart.items[id].count === 1) {
        dispatch.cart.removeFromCart(id)
        return
      }

      dispatch.cart.decreaseProductCount(id)
    },
    [cart.items, dispatch.cart],
  )

  const handleDeliveryButtonClick = useCallback(
    (orderType: OrderType) => {
      dispatch.cart.setOrderType(orderType)
      if (isMobile && orderPage === OrderPageType.Order && hasRestrictedItemsInCart && orderType === OrderType.TakeAway && !oneCheckout) {
        setRestrictedModalOpen(true);
      }
    },
    [dispatch.cart, isMobile, orderPage, hasRestrictedItemsInCart, oneCheckout],
  )

  useEffect(() => {
    if (user?.defaultOrderType && user?.defaultOrderType !== "none" && !cart?.orderType) {
      dispatch.cart.setOrderType(user?.defaultOrderType as unknown as OrderType)
    }

    if (user?.defaultOrderType === QrDefaultOrderType.TakeAway && hasRestrictedItemsInCart && !cart?.orderType) {
      dispatch.cart.setOrderType(OrderType.None)

    }
  }, [cart?.orderType, user?.defaultOrderType, hasRestrictedItemsInCart, oneCheckout])

  const handleCommentClick = useCallback(() => {
    navigate('comment', { state: { backgroundLocation: location } })
  }, [location, navigate])

  const handleDiscountClick = useCallback(() => {
    navigate('discount', { state: { backgroundLocation: location } })
  }, [location, navigate])

  const handleCardOrTransferClick = useCallback(
    async (paymentType: PaymentType) => {
      const data = await createOrder({
        paymentType,
        loyaltyCode:
          loyaltyCode?.loyaltyCode && loyaltyCode.additionalPaymentRequired ? loyaltyCode.loyaltyCode : undefined,
      })

      if (!data?.externalPaymentLink) {
        return
      }

      window.open(data.externalPaymentLink, '_self')
    },
    [createOrder, loyaltyCode?.additionalPaymentRequired, loyaltyCode?.loyaltyCode],
  )

  const handleCashClick = useCallback(
    async (paymentType: PaymentType) => {
      const data = await createOrder({ paymentType })
      if (!data) {
        return
      }

      navigateToStatus(data.id!)
    },
    [createOrder, navigateToStatus],
  )

  const handleClientCodeConfirm = useCallback(
    async (code: string, paymentType?: PaymentType) => {
      const pType = paymentType ?? selectedPaymentType
      if (!pType) {
        return
      }

      try {
        toggleOrderLoadingModal()

        const loyaltyCodeData = await checkLoyaltyCode(code)

        let orderId: string | undefined

        if (loyaltyCodeData.loyaltyCode && !loyaltyCodeData.clientCode) {
          if (loyaltyCodeData.additionalPaymentRequired) {
            setClientCodeModalOpen(false)

            return
          }

          const loyaltyCodeOrderData = await createOrder({ paymentType: pType, loyaltyCode: code })
          if (!loyaltyCodeOrderData) {
            return
          }

          orderId = loyaltyCodeOrderData.id
        }

        if (loyaltyCodeData.clientCode && !loyaltyCodeData.loyaltyCode) {
          const clientCodeOrderData = await createOrder({ paymentType: pType, clientCode: Number(code) })
          if (!clientCodeOrderData) {
            return
          }

          orderId = clientCodeOrderData.id
        }

        if (!orderId) {
          return
        }

        navigateToStatus(orderId)
      } catch (error) {
        getAndSetError(error)
      } finally {
        toggleOrderLoadingModal()
      }
    },
    [checkLoyaltyCode, createOrder, navigateToStatus, selectedPaymentType, toggleOrderLoadingModal],
  )

  const handleClientClick = useCallback(
    (paymentType: PaymentType) => {
      setSelectedPaymentType(paymentType)

      if (loyaltyCode?.loyaltyCode) {
        handleClientCodeConfirm(loyaltyCode.loyaltyCode, paymentType)
        return
      }

      toggleClientCodeModal()
    },
    [handleClientCodeConfirm, loyaltyCode?.loyaltyCode, toggleClientCodeModal],
  )

  const handlePagerNumberConfirm = useCallback(
    async (pagerNumber: string) => {
      dispatch.cart.updatePagerNumber(pagerNumber)

      togglePagerNumberModal()

      if (user?.phoneNumberInputType !== InputFieldType.None) {
        togglePhoneNumberModal()
        return
      }

      if (user?.guestIdentificationInputType !== InputFieldType.None) {
        toggleGuestIdentificationModal()
        return
      }

      if (await createLoyaltyOrder()) {
        return
      }

      togglePaymentSelectModal()
    },
    [
      createLoyaltyOrder,
      dispatch.cart,
      toggleGuestIdentificationModal,
      togglePagerNumberModal,
      togglePaymentSelectModal,
      togglePhoneNumberModal,
      user?.guestIdentificationInputType,
      user?.phoneNumberInputType,
    ],
  )

  const handlePhoneNumberConfirm = async (phoneNumber: string) => {
    dispatch.cart.updatePhoneNumber(phoneNumber)

    togglePhoneNumberModal()

    if (user?.guestIdentificationInputType !== InputFieldType.None) {
      toggleGuestIdentificationModal()
      return
    }

    if (await createLoyaltyOrder()) {
      return
    }

    togglePaymentSelectModal()
  }

  const handlePhoneNumberSkip = async () => {
    togglePhoneNumberModal()

    if (user?.guestIdentificationInputType !== InputFieldType.None) {
      toggleGuestIdentificationModal()
      return
    }

    if (await createLoyaltyOrder()) {
      return
    }

    togglePaymentSelectModal()
  }

  const handleGuestIdentificationConfirm = useCallback(
    async (guestIdentification: string) => {
      dispatch.cart.updateGuestIdentification(guestIdentification)

      toggleGuestIdentificationModal()

      if (await createLoyaltyOrder()) {
        return
      }

      togglePaymentSelectModal()
    },
    [createLoyaltyOrder, dispatch.cart, toggleGuestIdentificationModal, togglePaymentSelectModal],
  )

  const handleAgeConfirm = useCallback(async () => {
    setShowAgeErrors(false)
    setAgeConfirmed(true)

    toggleConfirmAgeModal()

    if (user?.requiredUserInput && isMobile) {
      togglePagerNumberModal()
      return
    }

    if (user?.guestIdentificationInputType !== InputFieldType.None) {
      toggleGuestIdentificationModal()
      return
    }

    if (await createLoyaltyOrder(true)) {
      return
    }

    togglePaymentSelectModal()
  }, [
    createLoyaltyOrder,
    isMobile,
    toggleConfirmAgeModal,
    toggleGuestIdentificationModal,
    togglePagerNumberModal,
    togglePaymentSelectModal,
    user?.guestIdentificationInputType,
    user?.requiredUserInput,
  ])

  const handleAgeCancel = useCallback(() => {
    setShowAgeErrors(true)
    setAgeConfirmed(false)

    toggleConfirmAgeModal()
  }, [toggleConfirmAgeModal])

  const handlePromoCodeInputBlur = async (code: string) => {
    if (!code) {
      return
    }

    try {
      setPromoCodeLoading(true)

      if (!cart.promoCode) {
        const data = await calculateOrderPrice({ promoCode: code })
        if (data?.promoCode) {
          dispatch.cart.updatePromoCode(data.promoCode)

          promoCodeCardInputRef.current?.clearInputValue()

          scrollToPromoCode()

          return
        }
      }

      if (!loyaltyCode?.loyaltyCode) {
        await checkLoyaltyCode(code)
      }

      promoCodeCardInputRef.current?.clearInputValue()

      scrollToPromoCode()
    } catch (error) {
      getAndSetError(error)
    } finally {
      setPromoCodeLoading(false)
    }
  }

  const handlePromoCodeRemove = () => {
    dispatch.orders.clearOrderPrice()

    dispatch.cart.clearPromoCode()
  }

  const handleLoyaltyCodeRemove = async () => {
    dispatch.orders.clearLoyaltyCode()

    await calculateOrderPrice()
  }

  const handleDurationComplete = async () => {
    if (!reservedTimeSlot?.id) {
      return
    }

    try {
      await dispatch.timeSlots.releaseTimeSlot(reservedTimeSlot.id)
    } catch (error) {
      console.error(error)
    } finally {
      dispatch.timeSlots.setReservedTimeSlot(null)
      dispatch.timeSlots.setSelectedTimeSlot(null)

      navigate(`/${params.tabletId}/checkout`, { replace: true, state: locationState })

      setExpiredSessionModalOpen(true)
    }
  }

  const handleExpiredSessionModalClose = () => {
    setPaymentSelectModalOpen(false)
    setConfirmAgeModalOpen(false)
    setClientCodeModalOpen(false)
    setPagerNumberModalOpen(false)
    setGuestIdentificationModalOpen(false)
    setPhoneNumberModalOpen(false)
    setExpiredSessionModalOpen(false)
  }

  const handleChooseTimeClick = () => {
    if (isCartEmpty) {
      setError({ errorMsg: t('common.emptyCartActionErrorMsg'), buttonText: t('common.emptyCartActionErrorButtonText') })
      return
    }

    navigateToDeliveryTime()
  }

  const renderTipsCard = () => {
    if (user?.tipsInputType === InputFieldType.None) {
      return null
    }

    return (
      <TipsCard
        title={t('pages.checkout.tips.cardLabel')}
        icon={TipsImg}
        values={[0, 5, 10, 15]}
        selectedValue={cart.tipsData}
        error={errors.tips && showErrors}
        onValueChange={dispatch.cart.updateTipsData}
      />
    )
  }

  const changeOrderType = () => {
    handleDeliveryButtonClick(OrderType.Table)
    setRestrictedModalOpen(false)
  }

  const editCartProducts = () => {
    setRestrictedModalOpen(false)
    setOrderPage(OrderPageType.Menu);

  }

  useEffect(() => {
    if (orderPage === OrderPageType.Menu && !oneCheckout) {
      const firstRestrictedProductOffsetTop = cartProductsRef.current?.getFirstRestrictedProductOffsetTop()

      if (typeof firstRestrictedProductOffsetTop !== 'number') {
        return
      }

      if (isMobile) {
        const offsetHeight = getMobileHeaderOffsetHeight()
        if (typeof offsetHeight !== 'number') {
          return
        }

        scrollWindow(firstRestrictedProductOffsetTop - offsetHeight - CART_PRODUCTS_GAP)

        return
      }
    }
  }, [cartProductsRef, isMobile, CART_PRODUCTS_GAP, restrictedItemModalOpen, orderPage, oneCheckout])


  const renderTotalPrice = () => {
    return (
      <AdditionalCartItemContainer>
        <AdditionalCartItem title={t('pages.checkout.totalPriceTitle')} price={cartVisibleTotalPrice} />
      </AdditionalCartItemContainer>
    )
  }

  const renderSubtotalPrice = () => {
    return (
      <AdditionalCartItemContainer>
        <AdditionalCartItem title={t('pages.status.totalPriceSubtitle')} price={getCartTotalPrice(cart)} />
      </AdditionalCartItemContainer>
    )
  }

  const renderDiscountPrice = () => {
    return (
      <AdditionalCartItemContainer biggerpadding={oneCheckout}>
        <AdditionalCartItem title={t('pages.checkout.discount')} price={orderPrice?.promoCodeDiscount} isDiscount hasShadow={isMobile && oneCheckout} />
      </AdditionalCartItemContainer>

    )
  }

  const renderTipsAmountCard = () => {
    if (user?.tipsInputType === InputFieldType.None || !cartTipsAmount) {
      return null
    }

    return (
      <AdditionalCartItemContainer biggerpadding={oneCheckout}>
        <AdditionalCartItem title={t('pages.checkout.tips.amountLabel')} price={cartTipsAmount} hasShadow={isMobile && oneCheckout} />
      </AdditionalCartItemContainer>
    )
  }

  const renderTerms = () => {
    return (
      <Terms>
        <Trans
          i18nKey="pages.checkout.terms"
          components={{
            pp: <TermsLink href={websiteData?.privacyPolicyUrl ?? process.env.VITE_APP_PP_URL} />,
            toc: <TermsLink href={websiteData?.termsConditionsUrl ?? process.env.VITE_APP_TOC_URL} />,
          }}
        />
      </Terms>
    )
  }

  const renderChooseTime = () => {
    if (!user?.enableTimeSlots) {
      return null
    }

    const date = dayjs(reservedTimeSlot?.expiration)
    const expirationDate = date.subtract(date.utcOffset(), 'm')

    const durationInSeconds = expirationDate.diff(dayjs(), 's')

    return (
      <ChooseTimeButtonContainer>
        <ChooseTimeButton
          selected={!!selectedTimeSlot}
          error={errors.timeSlot && showErrors}
          imgSrc={ClockIcon}
          title={t('common.chooseTimeButtonText', { time: deliveryTime })}
          durationInSeconds={durationInSeconds}
          showTimer={!!reservedTimeSlot}
          onClick={handleChooseTimeClick}
          onDurationComplete={handleDurationComplete}
        />
      </ChooseTimeButtonContainer>
    )
  }

  const renderServiceFee = () => {
    if (user?.serviceFeeType === ServiceFeeType.None || !orderPrice?.serviceFeeAmount) {
      return null
    }

    return (
      <AdditionalCartItemContainer biggerpadding={oneCheckout}>
        <AdditionalCartItem title={getServiceFeeTitle()} price={orderPrice.serviceFeeAmount} hasShadow={isMobile && oneCheckout} />
      </AdditionalCartItemContainer>
    )
  }

  const releaseTimeSlot = async (id: string) => {
    try {
      await dispatch.timeSlots.releaseTimeSlot(id)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTopTimeClick = () => {

    dispatch.timeSlots.setSoonAsPossible(true)

    if (reservedTimeSlot) {
      releaseTimeSlot(reservedTimeSlot.id!)
    }

    dispatch.timeSlots.setSelectedTimeSlot(null)

    dispatch.timeSlots.setReservedTimeSlot(null)

  }

  useEffect(() => {
    if (locationState?.errorMsg) {
      setError({ errorMsg: locationState.errorMsg })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    calculateOrderPriceAndCheckLoyaltyCode()
  }, [calculateOrderPriceAndCheckLoyaltyCode, cart])

  useEffect(() => {
    if (!isMobile) {
      return
    }

    scrollWindow(-100)
  }, [isMobile, scrollWindow])

  useEffect(() => {
    if (!isMobile || !locationState?.fromDiscount || locationState.errorMsg) {
      return
    }

    scrollToPromoCode()

    navigate(`/${params.tabletId}/checkout`, {
      replace: true,
      state: { backgroundLocation: locationState.backgroundLocation },
    })
  }, [
    isMobile,
    locationState?.backgroundLocation,
    locationState?.fromDiscount,
    navigate,
    params.tabletId,
    scrollToPromoCode,
  ])

  const renderOrderContent = () => {
    return (
      <ContentContainer>
        {acceptsAllOrderTypes && (
          <DeliveryButtonsContainer>
            <IconActionButton
              selected={cart.orderType === OrderType.TakeAway}
              disabled={!user?.isAcceptingTakeAwayOrders}
              error={errors.orderType && showErrors}
              imgSrc={BagIcon}
              onClick={() => handleDeliveryButtonClick(OrderType.TakeAway)}
            >
              {t('common.takeAwayButtonText')}
            </IconActionButton>
            <IconActionButton
              selected={cart.orderType === OrderType.Table}
              disabled={!user?.isAcceptingTableOrders}
              error={errors.orderType && showErrors}
              imgSrc={PlateIcon}
              onClick={() => handleDeliveryButtonClick(OrderType.Table)}
            >
              {t('common.eatInButtonText')}
            </IconActionButton>
          </DeliveryButtonsContainer>
        )}
        {!!user?.enableTimeSlots && <Text style={{ paddingBottom: 16, paddingTop: 12 }} type="productPageOptionName" color='baseItemTextColor'>{t('pages.deliveryTime.title') + ":"}</Text>}
        {
          !!user?.isOpen && !!user?.enableTimeSlots && (
            <OptionButtonContainer>
              <OptionButton
                disabled={loading}
                selected={soonAsPossible}
                primary
                RightComponent={
                  translations?.['Timeslot.Info'] ? (
                    <Popover title={translations['Timeslot.Info']} titleLower={translations['Timeslot.InfoLower']} />
                  ) : null
                }
                onClick={handleTopTimeClick}
              >
                {t('common.soonAsPossible')}
              </OptionButton>
            </OptionButtonContainer>

          )
        }
        {renderChooseTime()}
        <AddInfoItemsContainer>
          {renderTipsCard()}
          {user?.qrInvoiceEmailInputType !== InputFieldType.None && (
            <EmailAndCompanyInfoCardInput
              ref={emailAndCompanyInfoCardInputRef}
              email={cart.invoiceEmail}
              isCompany={cart.isCompany}
              companyInfo={cart.companyInfo}
              emailError={errors.invoiceEmail && showErrors}
              companyInfoErrors={showErrors ? errors.companyInfo : null}
              emailRequired={user?.qrInvoiceEmailInputType === InputFieldType.Required}
              Icon={EmailIcon}
              onEmailBlur={dispatch.cart.updateInvoiceEmail}
              onCompanyInfoBlur={dispatch.cart.updateCompanyInfo}
              onIsCompanyClick={dispatch.cart.updateIsCompany}
              hasShadow
            />
          )}
          {!!user?.allowPromoCodes && (
            <>
              {(!orderPrice?.promoCode || !loyaltyCode?.loyaltyCode) && (
                <AddInfoItem
                  title={t('common.discountTitle')}
                  itemTitle={t('pages.checkout.discount.itemTitle')}
                  itemDescription={t('pages.checkout.discount.itemDescription')}
                  Icon={<DiscountIcon />}
                  onItemClick={handleDiscountClick}
                />
              )}
              {!!orderPrice?.promoCode && !isCartEmpty && (
                <AddInfoItem
                  itemTitle={t('pages.checkout.discount.itemAddedTitle')}
                  itemDescription={`- ${promoCodeDiscountWithCurrency}`}
                  showRemoveButton
                  Icon={<DiscountIcon />}
                  onRemoveClick={handlePromoCodeRemove}
                />
              )}
              {!!loyaltyCode?.loyaltyCode && (
                <AddInfoItem
                  itemTitle={t('pages.checkout.loyaltyCode.label')}
                  itemDescription={`${loyaltyCode.loyaltyCode} (- ${availableLoyaltyAmountWithCurrency})`}
                  showRemoveButton
                  Icon={<DiscountIcon />}
                  onRemoveClick={handleLoyaltyCodeRemove}
                />
              )}
            </>
          )}
          {renderSubtotalPrice()}
          {cart.orderType === OrderType.TakeAway && cartTakeAwayPrice > 0 && (
            <AdditionalCartItemContainer>
              <AdditionalCartItem title={t('common.takeAwayPriceTitle')} price={cartTakeAwayPrice} />
            </AdditionalCartItemContainer>
          )}
          {cart.orderType === OrderType.TakeAway && cartTakeAwayDiscountAmount > 0 && (
            <AdditionalCartItemContainer>
              <AdditionalCartItem
                title={t('common.takeAwayDiscountAmountTitle')}
                price={cartTakeAwayDiscountAmount}
                isDiscount
              />
            </AdditionalCartItemContainer>
          )}
          {renderTipsAmountCard()}
          {renderServiceFee()}
          {!!orderPrice?.promoCode && !isCartEmpty && renderDiscountPrice()}
          {renderTotalPrice()}
        </AddInfoItemsContainer >
      </ContentContainer >
    )
  }

  const renderMenuContent = () => {
    return (
      <ContentContainer>
        {
          !!user?.enableOrderComment && <AddInfoItemsContainer>
            <AddInfoItem
              title={t('common.commentTitle')}
              itemTitle={t('pages.checkout.comment.itemTitle')}
              itemDescription={cart.comment || t('pages.checkout.comment.itemDescription')}
              Icon={<CommentIcon />}
              onItemClick={handleCommentClick}
            />
          </AddInfoItemsContainer>
        }
        <CartProducts
          ref={cartProductsRef}
          cartItems={Object.values(cart.items)}
          editing
          cartProductsWithAgeToComply={cartProductsWithAgeToComply}
          showErrors={showAgeErrors}
          hasShadow
          restrictedErrorMsg={restrictedErrorMsg}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </ContentContainer >
    )
  }

  const renderAllContent = () => {
    return (
      <ContentContainer>
        {acceptsAllOrderTypes && (
          <DeliveryButtonsContainer>
            <IconActionButton
              selected={cart.orderType === OrderType.TakeAway}
              disabled={!user?.isAcceptingTakeAwayOrders}
              error={errors.orderType && showErrors}
              imgSrc={BagIcon}
              onClick={() => handleDeliveryButtonClick(OrderType.TakeAway)}
            >
              {t('common.takeAwayButtonText')}
            </IconActionButton>
            <IconActionButton
              selected={cart.orderType === OrderType.Table}
              disabled={!user?.isAcceptingTableOrders}
              error={errors.orderType && showErrors}
              imgSrc={PlateIcon}
              onClick={() => handleDeliveryButtonClick(OrderType.Table)}
            >
              {t('common.eatInButtonText')}
            </IconActionButton>
          </DeliveryButtonsContainer>
        )}
        {!!user?.enableTimeSlots && <Text style={{ paddingBottom: 16, paddingTop: 12 }} type="productPageOptionName" color='baseItemTextColor'>{t('pages.deliveryTime.title') + ":"}</Text>}
        {
          !!user?.isOpen && !!user?.enableTimeSlots && (
            <OptionButtonContainer>
              <OptionButton
                disabled={loading}
                selected={soonAsPossible}
                primary
                RightComponent={
                  translations?.['Timeslot.Info'] ? (
                    <Popover title={translations['Timeslot.Info']} titleLower={translations['Timeslot.InfoLower']} />
                  ) : null
                }
                onClick={handleTopTimeClick}
              >
                {t('common.soonAsPossible')}
              </OptionButton>
            </OptionButtonContainer>

          )
        }
        {renderChooseTime()}
        {
          !!user?.enableOrderComment && <AddInfoItemsContainer>
            <AddInfoItem
              title={t('common.commentTitle')}
              itemTitle={t('pages.checkout.comment.itemTitle')}
              itemDescription={cart.comment || t('pages.checkout.comment.itemDescription')}
              Icon={<CommentIcon />}
              onItemClick={handleCommentClick}
            />
          </AddInfoItemsContainer>
        }
        <CartProducts
          ref={cartProductsRef}
          cartItems={Object.values(cart.items)}
          editing
          cartProductsWithAgeToComply={cartProductsWithAgeToComply}
          showErrors={showAgeErrors}
          hasShadow
          restrictedErrorMsg={restrictedErrorMsg}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
        {cart.orderType === OrderType.TakeAway && cartTakeAwayPrice > 0 && (
          <AdditionalCartItemContainer biggerpadding={oneCheckout}>
            <AdditionalCartItem title={t('common.takeAwayPriceTitle')} price={cartTakeAwayPrice} hasShadow={oneCheckout} />
          </AdditionalCartItemContainer>
        )}
        {cart.orderType === OrderType.TakeAway && cartTakeAwayDiscountAmount > 0 && (
          <AdditionalCartItemContainer biggerpadding={oneCheckout}>
            <AdditionalCartItem
              title={t('common.takeAwayDiscountAmountTitle')}
              price={cartTakeAwayDiscountAmount}
              isDiscount
              hasShadow={oneCheckout}
            />
          </AdditionalCartItemContainer>
        )}
        {renderTipsAmountCard()}
        {renderServiceFee()}
        <AddInfoItemsContainer>
          {renderTipsCard()}
          {user?.qrInvoiceEmailInputType !== InputFieldType.None && (
            <EmailAndCompanyInfoCardInput
              ref={emailAndCompanyInfoCardInputRef}
              email={cart.invoiceEmail}
              isCompany={cart.isCompany}
              companyInfo={cart.companyInfo}
              emailError={errors.invoiceEmail && showErrors}
              companyInfoErrors={showErrors ? errors.companyInfo : null}
              emailRequired={user?.qrInvoiceEmailInputType === InputFieldType.Required}
              Icon={EmailIcon}
              onEmailBlur={dispatch.cart.updateInvoiceEmail}
              onCompanyInfoBlur={dispatch.cart.updateCompanyInfo}
              onIsCompanyClick={dispatch.cart.updateIsCompany}
              hasShadow
            />
          )}

          {!!user?.allowPromoCodes && (
            <>
              {(!orderPrice?.promoCode || !loyaltyCode?.loyaltyCode) && (
                <AddInfoItem
                  title={t('common.discountTitle')}
                  itemTitle={t('pages.checkout.discount.itemTitle')}
                  itemDescription={t('pages.checkout.discount.itemDescription')}
                  Icon={<DiscountIcon />}
                  onItemClick={handleDiscountClick}
                />
              )}
              {!!orderPrice?.promoCode && !isCartEmpty && (
                <AddInfoItem
                  itemTitle={t('pages.checkout.discount.itemAddedTitle')}
                  itemDescription={`- ${promoCodeDiscountWithCurrency}`}
                  showRemoveButton
                  Icon={<DiscountIcon />}
                  onRemoveClick={handlePromoCodeRemove}
                />
              )}
              {!!loyaltyCode?.loyaltyCode && (
                <AddInfoItem
                  itemTitle={t('pages.checkout.loyaltyCode.label')}
                  itemDescription={`${loyaltyCode.loyaltyCode} (- ${availableLoyaltyAmountWithCurrency})`}
                  showRemoveButton
                  Icon={<DiscountIcon />}
                  onRemoveClick={handleLoyaltyCodeRemove}
                />
              )}
            </>
          )}
        </AddInfoItemsContainer>
      </ContentContainer>
    )
  }

  return (
    <>
      {isMobile ? (
        <MainContainer>
          <HeaderContainer ref={headerContainerRef}>
            <Header locationTitle={user?.restaurantDisplayTitle!} onBackClick={handleBackClick} menuPage={orderPage} />
          </HeaderContainer>
          {oneCheckout ? renderAllContent() : orderPage === OrderPageType.Menu ? renderMenuContent() : renderOrderContent()}
          <FooterContainer>
            <TermsContainer>{renderTerms()}</TermsContainer>
            <Footer
              hideLeftContent
              cartSum={orderPage === OrderPageType.Menu ? getCartTotalPrice(cart) : cartVisibleTotalPrice}
              intercomEnabled={user?.enableSupportChat}
              CartContentComponent={
                <Text type="productPageOptionName" color="primaryTextColor">
                  {t('pages.checkout.cartButtonText')}
                </Text>
              }
              onCartClick={handleCartClick}
            />
          </FooterContainer>
        </MainContainer>
      ) : (
        <NewModal isOpen width="72.188rem" height="55.938rem" showCloseButton onRequestClose={handleBackClick} style={{ overlay: { zIndex: 998 } }}>
          <DesktopMainContainer>
            <Header hasShadow locationTitle={user?.restaurantDisplayTitle!} />
            <DesktopContentMainContainer>
              <DesktopContentContainer ref={desktopContentContainerRef}>
                {acceptsAllOrderTypes && (
                  <div>
                    <DesktopOrderTypeTitleContainer>
                      <Text
                        type="checkoutTitle"
                        color={
                          (errors.orderType || errors.timeSlot) && showErrors ? 'errorTextColor' : 'baseItemTextColor'
                        }
                      >
                        {t('pages.checkout.deliveryTitle')}
                      </Text>
                    </DesktopOrderTypeTitleContainer>
                    <DeliveryButtonsContainer>
                      <IconActionButton
                        selected={cart.orderType === OrderType.TakeAway}
                        disabled={!user?.isAcceptingTakeAwayOrders}
                        error={errors.orderType && showErrors}
                        imgSrc={BagIcon}
                        onClick={() => handleDeliveryButtonClick(OrderType.TakeAway)}
                      >
                        {t('common.takeAwayButtonText')}
                      </IconActionButton>
                      <IconActionButton
                        selected={cart.orderType === OrderType.Table}
                        disabled={!user?.isAcceptingTableOrders}
                        error={errors.orderType && showErrors}
                        imgSrc={PlateIcon}
                        onClick={() => handleDeliveryButtonClick(OrderType.Table)}
                      >
                        {t('common.eatInButtonText')}
                      </IconActionButton>
                    </DeliveryButtonsContainer>
                  </div>
                )}
                {!!user?.enableTimeSlots && <Text style={{ paddingBottom: 16 }} type="checkoutTitle" color='baseItemTextColor'>{t('pages.deliveryTime.title') + ":"}</Text>}
                {
                  !!user?.isOpen && !!user?.enableTimeSlots && (
                    <OptionButtonContainer>
                      <OptionButton
                        disabled={loading}
                        selected={soonAsPossible}
                        primary
                        RightComponent={
                          translations?.['Timeslot.Info'] ? (
                            <Popover title={translations['Timeslot.Info']} titleLower={translations['Timeslot.InfoLower']} />
                          ) : null
                        }
                        onClick={handleTopTimeClick}
                      >
                        {t('common.soonAsPossible')}
                      </OptionButton>
                    </OptionButtonContainer>

                  )
                }
                {renderChooseTime()}
                <DesktopInfoItemsContainer>
                  {renderTipsCard()}
                  {user?.qrInvoiceEmailInputType !== InputFieldType.None && (
                    <EmailAndCompanyInfoCardInput
                      ref={emailAndCompanyInfoCardInputRef}
                      email={cart.invoiceEmail}
                      isCompany={cart.isCompany}
                      companyInfo={cart.companyInfo}
                      emailError={errors.invoiceEmail && showErrors}
                      companyInfoErrors={showErrors ? errors.companyInfo : null}
                      emailRequired={user?.qrInvoiceEmailInputType === InputFieldType.Required}
                      Icon={EmailIcon}
                      onEmailBlur={dispatch.cart.updateInvoiceEmail}
                      onCompanyInfoBlur={dispatch.cart.updateCompanyInfo}
                      onIsCompanyClick={dispatch.cart.updateIsCompany}
                      hasShadow
                    />
                  )}
                  {!!user?.requiredUserInput && (
                    <CardInput
                      value={cart.pagerNumber}
                      label={t('pages.checkout.components.pagerNumberModal.title')}
                      placeholder={t('pages.checkout.components.pagerNumberModal.placeholder')}
                      inputRequired
                      error={errors.pagerNumber && showErrors}
                      Icon={ReceiptIcon}
                      onInputBlur={dispatch.cart.updatePagerNumber}
                    />
                  )}
                  {!!user?.enableOrderComment && (
                    <CardInput
                      value={cart.comment}
                      label={t('pages.comment.title')}
                      placeholder={t('pages.comment.placeholder')}
                      Icon={CommentIcon}
                      onInputBlur={dispatch.cart.updateComment}
                    />
                  )}
                  {!!user?.allowPromoCodes && (
                    <>
                      {(!orderPrice?.promoCode || !loyaltyCode?.loyaltyCode) && (
                        <CardInput
                          ref={promoCodeCardInputRef}
                          disabled={promoCodeLoading}
                          label={t('pages.checkout.discount.itemTitle')}
                          placeholder={t('pages.checkout.discount.itemDescription')}
                          Icon={DiscountIcon}
                          onInputBlur={handlePromoCodeInputBlur}
                        />
                      )}
                      {!!orderPrice?.promoCode && !isCartEmpty && (
                        <CardInput
                          disabled
                          value={`- ${promoCodeDiscountWithCurrency}`}
                          label={t('pages.checkout.discount.itemAddedTitle')}
                          showRemoveButton
                          Icon={DiscountIcon}
                          onRemoveClick={handlePromoCodeRemove}
                        />
                      )}
                      {!!loyaltyCode?.loyaltyCode && (
                        <CardInput
                          disabled
                          value={`${loyaltyCode.loyaltyCode} (- ${availableLoyaltyAmountWithCurrency})`}
                          label={t('pages.checkout.loyaltyCode.label')}
                          showRemoveButton
                          Icon={DiscountIcon}
                          onRemoveClick={handleLoyaltyCodeRemove}
                        />
                      )}
                    </>
                  )}
                </DesktopInfoItemsContainer>
              </DesktopContentContainer>
              <DesktopDivider />
              <DesktopContentRightContainer>
                <DesktopContentRightTopContainer ref={desktopContentRightTopContainerRef}>
                  <CartProducts
                    ref={cartProductsRef}
                    cartItems={Object.values(cart.items)}
                    editing
                    cartProductsWithAgeToComply={cartProductsWithAgeToComply}
                    showErrors={showAgeErrors}
                    hasShadow
                    restrictedErrorMsg={restrictedErrorMsg}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                  />
                  {cart.orderType === OrderType.TakeAway && cartTakeAwayPrice > 0 && (
                    <AdditionalCartItemContainer>
                      <AdditionalCartItem title={t('common.takeAwayPriceTitle')} price={cartTakeAwayPrice} />
                    </AdditionalCartItemContainer>
                  )}
                  {cart.orderType === OrderType.TakeAway && cartTakeAwayDiscountAmount > 0 && (
                    <AdditionalCartItemContainer>
                      <AdditionalCartItem
                        title={t('common.takeAwayDiscountAmountTitle') + ":"}
                        price={cartTakeAwayDiscountAmount}
                        isDiscount
                      />
                    </AdditionalCartItemContainer>
                  )}
                  {renderTipsAmountCard()}
                  {renderServiceFee()}
                </DesktopContentRightTopContainer>
                <DesktopContentRightBottomContainer>{renderTerms()}</DesktopContentRightBottomContainer>
              </DesktopContentRightContainer>
            </DesktopContentMainContainer>
            <Footer
              hideLeftContent
              showMobileVersion
              hideBrandLogo
              cartSum={cartVisibleTotalPrice}
              intercomEnabled={user?.enableSupportChat}
              CartContentComponent={
                <Text type="productPageOptionName" color="primaryTextColor">
                  {t('pages.checkout.cartButtonText')}
                </Text>
              }
              onCartClick={handleCartClick}
            />
          </DesktopMainContainer>
        </NewModal >
      )}
      <PaymentSelectModal
        isOpen={paymentSelectModalOpen}
        disabled={loading}
        showOnlyWeb={loyaltyCode?.additionalPaymentRequired}
        onCardOrTransferClick={handleCardOrTransferClick}
        onCashClick={handleCashClick}
        onClientClick={handleClientClick}
        onClose={togglePaymentSelectModal}
      />
      <RestrictedItemModal isOpen={restrictedItemModalOpen} changeOrderType={changeOrderType} editCartProducts={editCartProducts} onBackClick={() => setRestrictedModalOpen(false)} />
      <ConfirmAgeModal
        isOpen={confirmAgeModalOpen}
        age={Object.values(cartProductsWithAgeToComply)[0]}
        onBack={toggleConfirmAgeModal}
        onCancel={handleAgeCancel}
        onConfirm={handleAgeConfirm}
      />
      <ClientCodeModal
        isOpen={clientCodeModalOpen}
        disabled={loading}
        onDone={handleClientCodeConfirm}
        onClose={toggleClientCodeModal}
      />
      <ErrorModal
        errorMsg={error?.errorMsg}
        ErrorMsgComponent={error?.ErrorMsgComponent}
        buttonText={error?.buttonText}
        onBackClick={clearError}
        onRetryClick={clearError}
      />
      <PagerNumberModal
        isOpen={pagerNumberModalOpen}
        pagerNumber={cart.pagerNumber}
        onDone={handlePagerNumberConfirm}
        onClose={togglePagerNumberModal}
      />
      <GuestIdentificationModal
        isOpen={guestIdentificationModalOpen}
        required={user?.guestIdentificationInputType === InputFieldType.Required}
        guestIdentification={cart.guestIdentification}
        onDone={handleGuestIdentificationConfirm}
        onClose={toggleGuestIdentificationModal}
      />
      <PhoneNumberModal
        isOpen={phoneNumberModalOpen}
        required={user?.phoneNumberInputType === InputFieldType.Required}
        onSkip={handlePhoneNumberSkip}
        onConfirm={handlePhoneNumberConfirm}
        onClose={togglePhoneNumberModal}
      />
      <OrderLoadingModal
        isOpen={orderLoadingModalOpen}
        title={t('pages.checkout.components.orderLoadingModal.title')}
        description={t('pages.checkout.components.orderLoadingModal.description')}
      />
      <ExpiredSessionModal isOpen={expiredSessionModalOpen} onClose={handleExpiredSessionModalClose} />
    </>
  )
}

export default Checkout
