import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { AnyStyledComponent } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Dispatch } from 'src/utilities/store'
import { OrderContract } from 'src/types/api'
import PageLoader from 'src/components/PageLoader'
import OrdersList, { OrdersListRef } from './components/OrdersList'
import LogoIcon from 'src/assets/icons/logo.svg?react'
import Text from 'src/components/Text'
import { DATE_FORMAT, dayjs } from 'src/utilities/dayjs'
import DatesNavbar, { DatesNavbarRef } from './components/DatesNavbar'
import { sizes } from 'src/utilities/theme'
import { useDocScrollTimeout } from 'src/utilities/hooks'
import ContentContainer from 'src/components/ContentContainer'

const ORDERS_LIST_GAP = 16

const MainContainer = styled(ContentContainer)`
  padding: 2rem 0;
`

const BrandLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`

const BrandLogo = styled(LogoIcon as unknown as AnyStyledComponent)`
  width: 17rem;
  max-width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.baseItemTextColor};
`

const OrdersCountContainer = styled.div`
  padding-top: 3rem;
`

const DatesNavbarContainer = styled.div`
  margin: 3rem 0 1rem 0;
  position: sticky;
  top: 0;
`

const OrdersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${ORDERS_LIST_GAP}px;
`

const Orders = () => {
  const [orders, setOrders] = useState<OrderContract[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [activeDate, setActiveDate] = useState<string>('')

  const dispatch = useDispatch<Dispatch>()

  const { t } = useTranslation()

  const ordersListRef = useRef<OrdersListRef>(null)
  const datesNavbarRef = useRef<DatesNavbarRef>(null)

  const params = useParams<{ sessionId: string }>()

  const orderDates = useMemo(() => {
    return Object.keys(
      orders.reduce<{ [key: string]: string }>((obj, order) => {
        const date = dayjs(order.startTimeStamp).format(DATE_FORMAT)
        obj[date] = date
        return obj
      }, {}),
    )
  }, [orders])

  const getOrders = async (sessionId: string) => {
    try {
      setLoading(true)

      const data = await dispatch.orders.fetchOrders(sessionId)

      const orders = [...data].reverse()

      setOrders(orders)

      if (!orders.length) {
        return
      }

      setActiveDate(dayjs(orders[0].startTimeStamp).format(DATE_FORMAT))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const scrollToDate = (date: string) => {
    datesNavbarRef.current?.scrollToDate(date)
  }

  const handleDateClick = (date: string) => {
    setActiveDate(date)

    const ordersScrollPosition = ordersListRef.current?.getScrollPosition(date)
    if (ordersScrollPosition) {
      window.scroll({
        top: ordersScrollPosition - sizes.categoriesListContainerHeight - ORDERS_LIST_GAP,
        behavior: 'smooth',
      })
    }

    scrollToDate(date)
  }

  useEffect(() => {
    if (!params.sessionId) {
      return
    }

    getOrders(params.sessionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sessionId])

  useDocScrollTimeout(() => {
    if (!orders.length) {
      return
    }

    const date = ordersListRef.current?.getDate(window.scrollY + sizes.categoriesListContainerHeight + ORDERS_LIST_GAP)
    if (!date) {
      setActiveDate(dayjs(orders[0].startTimeStamp).format(DATE_FORMAT))
      return
    }

    setActiveDate(date)

    scrollToDate(date)
  })

  if (loading) {
    return <PageLoader />
  }

  return (
    <MainContainer>
      <ContentContainer>
        <BrandLogoContainer>
          <BrandLogo />
        </BrandLogoContainer>

        <OrdersCountContainer>
          <Text type="productPageTitle" align="center" color="baseItemTextColor">
            {t('pages.orders.totalOrdersCount', { count: orders.length })}
          </Text>
        </OrdersCountContainer>
      </ContentContainer>

      {!!orders.length && (
        <>
          <DatesNavbarContainer>
            <DatesNavbar
              ref={datesNavbarRef}
              activeDate={activeDate}
              dates={orderDates}
              onDateClick={handleDateClick}
            />
          </DatesNavbarContainer>

          <ContentContainer>
            <OrdersListContainer>
              <OrdersList ref={ordersListRef} orders={orders} />
            </OrdersListContainer>
          </ContentContainer>
        </>
      )}
    </MainContainer>
  )
}

export default Orders
