import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  SVGProps,
  useEffect,
  useState,
} from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Text from 'src/components/Text'
import { useMediaQuery } from 'src/utilities/hooks'
import { CompanyInformationContract, SendReceipEmailContract } from 'src/types/api'
import CheckboxItem from 'src/components/CheckboxItem'
import { allowOnlyIntegers } from 'src/utilities/functions'
import { Control, FieldError } from 'react-hook-form'

type FormSchema = SendReceipEmailContract;

interface EmailAndCompanyInfoCardInputProps {
  email?: string
  isCompany?: boolean
  companyInfo?: Partial<CompanyInformationContract>
  emailError?: boolean
  companyInfoErrors?: { [key in keyof CompanyInformationContract]?: boolean | undefined | FieldError } | null,
  emailRequired?: boolean
  hasShadow?: boolean
  isModal?: boolean
  Icon?: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  onEmailBlur?: (email: string) => void
  onCompanyInfoBlur?: (companyInfo: Partial<CompanyInformationContract>) => void
  onIsCompanyClick?: (isCompany: boolean) => void
  control?: Control<FormSchema>
}

interface LabelProps {
  error?: boolean
}

interface InputProps {
  error?: boolean | FieldError | undefined
}

interface CompanyInfoInputData {
  name: keyof CompanyInformationContract
  label: string
  placeholder: string
  onKeyDown?: KeyboardEventHandler<HTMLElement>
}

interface CardMainContainerProps {
  isModal?: boolean
}

const CardMainContainer = styled.div<CardMainContainerProps>`
  @media ${({ theme }) => theme.queries.mobile} {
    display: grid;
    row-gap: ${({ isModal }) => isModal ? 0.125 : 1.125}rem;
  }
`

interface CardContainer {
  hasShadow?: boolean
  hasIcon?: boolean
}

const CardContainer = styled.div<CardContainer>`
  background-color: ${({ theme, hasIcon }) => hasIcon && theme.colors.baseItemColor};
  ${({ hasShadow }) => hasShadow ? "box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);" : null}
  border-radius: 0.563rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: ${({ hasIcon }) => hasIcon ? 'auto 1fr' : 'auto'};
  padding: ${({ hasIcon }) => hasIcon ? '1rem 0.75rem 1rem 0rem' : '1rem 0.75rem 0.75rem 0px'};
`

const CardContentContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
`

const IconContainer = styled.div`
  width: 5rem;
  display: grid;
  justify-content: center;
  padding-top: 1.5rem;

  @media ${({ theme }) => theme.queries.mobile} {
    width: 3.813rem;
    padding-top: 0.15rem;
  }
`

const InputsContainer = styled.div`
  display: grid;
  row-gap: 1rem;
`

const Input = styled.input<InputProps>`
  height: 2.063rem;
  padding: 0 0.75rem;
  border: 1px solid rgba(60, 60, 67, 0.29);
  border-radius: 0.563rem;
  outline: none;
  color: ${({ theme }) => theme.colors.primaryTextColor};
  background-color: ${({ theme }) => theme.colors.fifthItemColor};
  ${({ theme }) => ({ ...theme.typography.placeholder })};

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholderTextColor};
    opacity: 0.7;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    ::placeholder {
      color: ${({ theme, error }) => (error ? theme.colors.errorTextColor : theme.colors.placeholderTextColor)};
    }
  }
`

const Label = styled(Text as unknown as AnyStyledComponent).attrs<LabelProps>(({ error }) => ({
  color: error ? 'errorTextColor' : 'baseItemTextColor',
})) <LabelProps>``

const EmailAndCompanyInfoCardInput = forwardRef<HTMLDivElement, EmailAndCompanyInfoCardInputProps>(
  (
    {
      email,
      isCompany,
      companyInfo,
      emailError,
      companyInfoErrors,
      emailRequired,
      Icon,
      isModal,
      hasShadow,
      onEmailBlur,
      onCompanyInfoBlur,
      onIsCompanyClick,
      control
    },
    ref,
  ) => {
    const [emailValue, setEmailValue] = useState<string>('')
    const [companyInfoValues, setCompanyInfoValues] = useState<Partial<CompanyInformationContract>>({})

    const { isMobile } = useMediaQuery()

    const { t } = useTranslation()

    const getFormattedLabel = (label: string, required?: boolean) => {
      return required ? `${label}*` : label
    }

    const getEmailFormattedLabel = () => {
      return getFormattedLabel(t('pages.checkout.invoiceEmail.label'), emailRequired)
    }

    const companyInfoInputsData: CompanyInfoInputData[] = [
      {
        name: 'name',
        label: getFormattedLabel(t('pages.checkout.companyInfo.name.label'), true),
        placeholder: t('pages.checkout.companyInfo.name.placeholder'),
      },
      {
        name: 'code',
        label: getFormattedLabel(t('pages.checkout.companyInfo.code.label'), true),
        placeholder: t('pages.checkout.companyInfo.code.placeholder'),
        onKeyDown: allowOnlyIntegers,
      },
      {
        name: 'vatCode',
        label: getFormattedLabel(t('pages.checkout.companyInfo.vatCode.label')),
        placeholder: t('pages.checkout.companyInfo.vatCode.placeholder'),
      },
      {
        name: 'address',
        label: getFormattedLabel(t('pages.checkout.companyInfo.address.label'), true),
        placeholder: t('pages.checkout.companyInfo.address.placeholder'),
      },
    ]

    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setEmailValue(e.target.value)
    }

    const handleCompanyInfoChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setCompanyInfoValues((prevCompanyInfoValues) => ({ ...prevCompanyInfoValues, [e.target.name]: e.target.value }))
    }

    const handleEmailBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      onEmailBlur?.(e.target.value)
    }

    const handleCompanyInfoBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      onCompanyInfoBlur?.({ [e.target.name]: e.target.value })
    }

    const renderCompanyInfoInputs = () => {
      return companyInfoInputsData.map((companyInfoInputData, index) => {
        const name = companyInfoInputData.name
        const error = companyInfoErrors?.[name]

        return (
          <CardContentContainer key={index}>
            {!isMobile && (
              <Label type="checkoutTitle" error={error}>
                {companyInfoInputData.label}
              </Label>
            )}

            {control ? <Input
              type="text"
              placeholder={companyInfoInputData.placeholder}
              error={error}
              onKeyDown={companyInfoInputData.onKeyDown}
              {...control.register(`companyInformation.${name}`)}
            /> : <Input
              type="text"
              name={name}
              placeholder={companyInfoInputData.placeholder}
              required={name !== 'address'}
              error={error}
              value={companyInfoValues[name] ?? ''}
              onChange={handleCompanyInfoChange}
              onBlur={handleCompanyInfoBlur}
              onKeyDown={companyInfoInputData.onKeyDown}
            />}
          </CardContentContainer>
        )
      })
    }

    useEffect(() => {
      if (!email) {
        return
      }

      setEmailValue(email)
    }, [email])

    useEffect(() => {
      if (!companyInfo) {
        return
      }

      setCompanyInfoValues(companyInfo)
    }, [companyInfo])

    return (
      <CardMainContainer ref={ref} isModal={isModal}>
        {isMobile && (
          <Label type="productPageOptionName" error={emailError}>
            {getEmailFormattedLabel()}
          </Label>
        )}

        <CardContainer hasShadow={hasShadow} hasIcon={!!Icon}>
          {!!Icon && (
            <IconContainer>
              <Icon width="auto" height={isMobile ? '1.813rem' : '2.188rem'} />
            </IconContainer>
          )}

          <InputsContainer>
            <CardContentContainer>
              {!isMobile && (
                <Label type="checkoutTitle" error={emailError}>
                  {getEmailFormattedLabel()}
                </Label>
              )}

              {control ? <Input
                type="email"
                autoComplete='email'
                placeholder={t('pages.checkout.invoice.placeholder')}
                error={emailError}
                {...control.register('email')}
              /> : <Input
                type="email"
                name="email"
                autoComplete='email'
                placeholder={t('pages.checkout.invoice.placeholder')}
                required={emailRequired}
                error={emailError}
                value={emailValue}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              />}
            </CardContentContainer>
            <CheckboxItem
              type="radio"
              label={t('pages.checkout.companyInfo.company')}
              checked={isCompany}
              onItemClick={onIsCompanyClick}
            />

            {!!isCompany && renderCompanyInfoInputs()}
          </InputsContainer>
        </CardContainer>
      </CardMainContainer>
    )
  },
)

export default EmailAndCompanyInfoCardInput
