import { memo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import OptionButton from './components/OptionButton'
import CardIcon from 'src/assets/icons/card.svg?react'
import WalletIcon from 'src/assets/icons/wallet.svg?react'
import ClientIcon from 'src/assets/icons/client.svg?react'
import TransferIcon from 'src/assets/icons/transfer.svg?react'
import { RootState } from 'src/utilities/store'
import { PaymentType, WebPaymentProvider } from 'src/types/api'
import PayIcons from './components/PayIcons'
import NewModal from 'src/components/NewModal'
import { useMediaQuery } from 'src/utilities/hooks'
import BankIcons from './components/BankIcons'
import {useTheme} from 'src/utilities/theme'

const ICONS = {
  card: CardIcon,
  wallet: WalletIcon,
  client: ClientIcon,
  transfer: TransferIcon,
}

interface PaymentSelectModalProps {
  isOpen: boolean
  disabled?: boolean
  showOnlyWeb?: boolean
  onCardOrTransferClick?: (paymentType: PaymentType) => void
  onCashClick?: (paymentType: PaymentType) => void
  onClientClick?: (paymentType: PaymentType) => void
  onClose?: () => void
}

const ContentContainer = styled.div`
  padding: 1rem 0.75rem;
`

const OptionButtonsContainer = styled.div`
  display: grid;
  row-gap: 0.625rem;
`

const PaymentSelectModal = (props: PaymentSelectModalProps) => {
  const { t } = useTranslation()

  const user = useSelector((state: RootState) => state.profile.user)

  const { isMobile } = useMediaQuery()

  const {theme} = useTheme()

  const Icon = ({name}: {name: keyof typeof ICONS}) => {
    const Ic = ICONS[name]

    return <Ic color={theme.colors.primaryTextColor} />
  }

  const renderPaymentOptions = () => {
    switch (user?.webPaymentProvider) {
      case WebPaymentProvider.Montonio:
        return (
          <>
            {!!user?.tablet?.allowClientCodePayment && (
              <OptionButton
                title={t('pages.checkout.components.paymentSelectModal.clientButtonText')}
                LeftIcon={<Icon name="client" />}
                disabled={props.disabled}
                onClick={() => props.onClientClick?.(PaymentType.ClientCode)}
              />
            )}
            {!!user?.tablet?.allowWebPayment && (
              <OptionButton
                title={t('pages.checkout.components.paymentSelectModal.cardOrTransferButtonText')}
                LeftIcon={<Icon name="card" />}
                RightIcon={<PayIcons />}
                disabled={props.disabled}
                onClick={() => props.onCardOrTransferClick?.(PaymentType.Web)}
              />
            )}
            {!!user?.tablet?.allowCashierPayment && (
              <OptionButton
                title={t('pages.checkout.components.paymentSelectModal.cashButtonText')}
                LeftIcon={<Icon name="wallet" />}
                disabled={props.disabled}
                onClick={() => props.onCashClick?.(PaymentType.Cashier)}
              />
            )}
          </>
        )
      case WebPaymentProvider.MontonioV2:
      case WebPaymentProvider.Adyen:
        return (
          <>
            {!!user?.enableWebTransferPayment && (
              <OptionButton
                title={t('pages.checkout.components.paymentSelectModal.transferButtonText')}
                LeftIcon={<Icon name="transfer" />}
                RightIcon={<BankIcons />}
                disabled={props.disabled}
                onClick={() => props.onCardOrTransferClick?.(PaymentType.WebTransfer)}
              />
            )}
            {!!user?.tablet?.allowClientCodePayment && !props.showOnlyWeb && (
              <OptionButton
                title={t('pages.checkout.components.paymentSelectModal.clientButtonText')}
                LeftIcon={<Icon name="client" />}
                disabled={props.disabled}
                onClick={() => props.onClientClick?.(PaymentType.ClientCode)}
              />
            )}
            {!!user?.enableWebCardPayment && (
              <OptionButton
                title={t('pages.checkout.components.paymentSelectModal.cardButtonText')}
                LeftIcon={<Icon name="card" />}
                RightIcon={<PayIcons />}
                disabled={props.disabled}
                onClick={() => props.onCardOrTransferClick?.(PaymentType.WebCard)}
              />
            )}
            {!!user?.tablet?.allowCashierPayment && !props.showOnlyWeb && (
              <OptionButton
                title={t('pages.checkout.components.paymentSelectModal.cashButtonText')}
                LeftIcon={<Icon name="wallet" />}
                disabled={props.disabled}
                onClick={() => props.onCashClick?.(PaymentType.Cashier)}
              />
            )}
          </>
        )
      default:
        return null
    }
  }

  return (
    <NewModal
      isOpen={props.isOpen}
      width={isMobile ? '100%' : '30rem'}
      noPadding={isMobile}
      alignment={isMobile ? 'bottom' : 'center'}
      onRequestClose={props.onClose}
    >
      <ContentContainer>
        <OptionButtonsContainer>{renderPaymentOptions()}</OptionButtonsContainer>
      </ContentContainer>
    </NewModal>
  )
}

export default memo(PaymentSelectModal)
