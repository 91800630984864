import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Dispatch, RootState } from 'src/utilities/store'
import {
  findDuplicatedCartItem,
  getCartItemWithPreselectedOptions,
  getCartTotalPrice,
  instantAddToCart,
  mediaUrl,
  setBodyOverflow,
} from 'src/utilities/functions'
import {
  MobileCategoriesHorizontalList,
  MobileCategoriesHorizontalListRef,
  DesktopCategoriesHorizontalList,
} from './components/CategoriesHorizontalList'
import ProductsList, { ProductsListRef } from 'src/components/ProductsList'
import { OrderType, ProductContract, ProductType } from 'src/types/api'
import CartImg from 'src/assets/images/cart.png'
import Footer from 'src/components/Footer'
import {
  selectCart,
  selectCartTotalPrice,
  selectIsCartEmpty,
  selectProductsWithCartItemsAndCategories,
} from 'src/models/cart'
import { selectProductsWithCartItems } from 'src/models/cart'
import IconButton from 'src/components/IconButton'
import { useDocScrollTimeout, useHeaderTitle, useMediaQuery, useWIndowSizes } from 'src/utilities/hooks'
import Header, { HEADER_HEIGHT as MOBILE_HEADER_HEIGHT } from 'src/components/Header'
import DesktopHeader, { BG_IMG_HEIGHT as DESKTOP_BG_IMG_HEIGHT } from 'src/components/DesktopHeader'
import { HEADER_HEIGHT as DESKTOP_HEADER_HEIGHT } from 'src/components/DesktopHeader/components/Header'
import { sizes as themeSizes } from 'src/utilities/theme'
import {
  DESKTOP_CATEGORIES_HEIGHT,
  DesktopCategoriesHorizontalListRef,
} from './components/CategoriesHorizontalList/components/DesktopCategoriesHorizontalList'
import DesktopProductsList, { DesktopProductsListRef } from 'src/components/DesktopProductsList'
import ContentContainer from 'src/components/ContentContainer'
import { selectAcceptsAllOrderTypes } from 'src/models/profile'
import MasterCategories, {
  MASTER_CATEGORIES_DESKTOP_HEIGHT_DIFFERENCE,
  MASTER_CATEGORIES_DESKTOP_NORMAL_HEIGHT,
  MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE,
  MASTER_CATEGORIES_MOBILE_NORMAL_HEIGHT,
  MASTER_CATEGORIES_MOBILE_SMALL_HEIGHT,
} from 'src/components/MasterCategories'
import { selectCategories, selectHasMasterCategories, selectSortedAndFilteredProducts } from 'src/models/catalog'
import { MOBILE_FOOTER_HEIGHT } from 'src/components/Footer/components/MobileFooter'
import Sidebar from 'src/components/Sidebar'
import HeaderContent from 'src/components/HeaderContent'
import { selectHasCustomPages } from 'src/models/website'
import TabletOfflineModal from './components/TabletOfflineModal'
import LocationButton from './components/LocationButton'

interface CategoriesHorizontalListContainerProps {
  masterCategoriesHeight?: number
}

const MainContainer = styled.div`
  padding-bottom: 7.75rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding-bottom: 7.125rem;
  }
`

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.baseItemColor};
`

const HeaderBgContentContainer = styled.div`
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-between;
  column-gap: 0.75rem;
`

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`

const CategoriesHorizontalListContainer = styled.div<CategoriesHorizontalListContainerProps>`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  position: sticky;
  top: ${({ masterCategoriesHeight = 0 }) => DESKTOP_HEADER_HEIGHT + masterCategoriesHeight}px;
  z-index: 1;
  padding-top: ${({ masterCategoriesHeight }) => (masterCategoriesHeight ? 0 : 1)}rem;

  @media ${({ theme }) => theme.queries.mobile} {
    top: ${({ masterCategoriesHeight = 0 }) => MOBILE_HEADER_HEIGHT + masterCategoriesHeight}px;
    padding-top: 0;
  }
`

const CartImgStyled = styled.img`
  width: 1.625rem;
  height: 1.625rem;
`

const MasterCategoriesContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  position: sticky;
  top: ${DESKTOP_HEADER_HEIGHT - MASTER_CATEGORIES_DESKTOP_HEIGHT_DIFFERENCE}px;
  z-index: 1;

  @media ${({ theme }) => theme.queries.mobile} {
    top: ${MOBILE_HEADER_HEIGHT - MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE}px;
  }
`

const getCeiledNumber = (value: number) => {
  return Math.ceil(value) + 1
}

const Home = () => {
  const [activeCategoryId, setActiveCategoryId] = useState<string>('')
  const [activeMasterCategoryId, setActiveMasterCategoryId] = useState<string>()
  const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(false)
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false)
  const [tabletOfflineModalOpen, setTabletOfflineModalOpen] = useState<boolean>(false)

  const masterCategories = useSelector((state: RootState) => state.catalog.catalog?.masterCategories)

  const user = useSelector((state: RootState) => state.profile.user)
  const group = useSelector((state: RootState) => state.group.group)
  const sessionId = useSelector((state: RootState) => state.app.sessionId)
  const cart = useSelector(selectCart)
  const { productsListScrollTopPosition, openRestaurants } = useSelector((state: RootState) => state.home)
  const categories = useSelector((state: RootState) => selectCategories(state, activeMasterCategoryId))
  const productsWithCartItems = useSelector((state: RootState) =>
    selectProductsWithCartItems(state, activeMasterCategoryId),
  )
  const productsWithCartItemsAndCategories = useSelector((state: RootState) =>
    selectProductsWithCartItemsAndCategories(state, activeMasterCategoryId),
  )
  const sortedAndFilteredProducts = useSelector((state: RootState) =>
    selectSortedAndFilteredProducts(state, activeMasterCategoryId),
  )
  const cartTotalPrice = useSelector(selectCartTotalPrice)
  const acceptsAllOrderTypes = useSelector(selectAcceptsAllOrderTypes)
  const hasMasterCategories = useSelector(selectHasMasterCategories)
  const hasCustomPages = useSelector(selectHasCustomPages)
  const isCartEmpty = useSelector(selectIsCartEmpty)

  const dispatch = useDispatch<Dispatch>()

  const categoriesListRef = useRef<MobileCategoriesHorizontalListRef>(null)
  const desktopCategoriesListRef = useRef<DesktopCategoriesHorizontalListRef>(null)
  const productsListRef = useRef<ProductsListRef>(null)
  const desktopProductsListRef = useRef<DesktopProductsListRef>(null)
  const previousActiveMasterCategoryId = useRef<string>('')

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams<{ tabletId: string }>()

  const sizes = useWIndowSizes()

  const { isMobile } = useMediaQuery()

  const headerTitle = useHeaderTitle()

  const masterCategoriesHeight = useMemo(() => {
    if (!hasMasterCategories) {
      return 0
    }

    return isMobile
      ? MASTER_CATEGORIES_MOBILE_NORMAL_HEIGHT - MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE
      : MASTER_CATEGORIES_DESKTOP_NORMAL_HEIGHT - MASTER_CATEGORIES_DESKTOP_HEIGHT_DIFFERENCE
  }, [hasMasterCategories, isMobile])

  const headerImgHeight = useMemo(() => {
    const imgHeight = sizes.width / themeSizes.aspectRatio
    const halfWindowHeight = window.innerHeight / 2
    if (imgHeight > halfWindowHeight) {
      return halfWindowHeight
    }
    return imgHeight
  }, [sizes.width])

  const headerImgsUrl = useMemo(() => {
    if (isMobile) {
      return user?.restaurantImagesMobile?.map((restaurantImageMobile) => {
        return mediaUrl(restaurantImageMobile, { w: 1536, h: 864 })
      })
    }

    return user?.restaurantImages?.map((restaurantImage) => {
      return mediaUrl(restaurantImage, { w: 2880, h: 800 })
    })
  }, [isMobile, user?.restaurantImages, user?.restaurantImagesMobile])

  const startPosition = useMemo(() => {
    if (isMobile) {
      return getCeiledNumber(
        headerImgHeight - MOBILE_HEADER_HEIGHT + (hasMasterCategories ? MASTER_CATEGORIES_MOBILE_HEIGHT_DIFFERENCE : 0),
      )
    }

    return getCeiledNumber(
      DESKTOP_BG_IMG_HEIGHT -
      DESKTOP_HEADER_HEIGHT +
      (hasMasterCategories ? MASTER_CATEGORIES_DESKTOP_HEIGHT_DIFFERENCE : 0),
    )
  }, [hasMasterCategories, headerImgHeight, isMobile])

  const toggleSidebar = () => {
    setSidebarVisible((prevSidebarVisible) => !prevSidebarVisible)
  }

  const openTabletOfflineModal = () => {
    setTabletOfflineModalOpen(true)
  }

  const closeTabletOfflineModal = () => {
    setTabletOfflineModalOpen(false)
  }

  const scrollToCategory = useCallback(
    (categoryId: string) => {
      if (isMobile) {
        categoriesListRef.current?.scrollToCategory(categoryId)
        return
      }

      desktopCategoriesListRef.current?.scrollToCategory(categoryId)
    },
    [isMobile],
  )

  const scrollToProduct = useCallback(
    (categoryId: string) => {
      if (isMobile) {
        const category = productsListRef.current?.getCategoryById(categoryId)
        if (!category) {
          return
        }

        window.scroll({ top: getCeiledNumber(category.scrollTop), behavior: 'smooth' })

        return
      }

      const scrollPosition = desktopProductsListRef.current?.getScrollPositionById(categoryId)
      if (!scrollPosition) {
        return
      }

      window.scroll({
        top: getCeiledNumber(
          scrollPosition - DESKTOP_HEADER_HEIGHT - DESKTOP_CATEGORIES_HEIGHT - masterCategoriesHeight,
        ),
        behavior: 'smooth',
      })
    },
    [isMobile, masterCategoriesHeight],
  )

  const calculateOrderPrice = useCallback(async () => {
    if (isCartEmpty) {
      return
    }

    try {
      await dispatch.orders.calculateOrderPrice({ userVisiblePrice: cartTotalPrice })
    } catch (error) {
      console.error(error)
    }
  }, [cartTotalPrice, dispatch.orders, isCartEmpty])

  const scrollToStart = useCallback(() => {
    window.scroll({ top: startPosition, behavior: 'smooth' })
  }, [startPosition])

  const updateProductsListScrollTopPosition = (scrollTop: number) => {
    dispatch.home.updateProductsListScrollTopPosition(scrollTop)
  }

  const navigateToProduct = useCallback(
    (data: { productId: string; cartId?: string }) => {
      const cartId = data.cartId

      const state = { backgroundLocation: location }

      navigate(data.productId, { state: cartId ? { ...state, cartId } : state })
    },
    [location, navigate],
  )

  const navigateToSearch = useCallback(() => {
    navigate('search', { state: { backgroundLocation: location } })
  }, [location, navigate])

  const navigateToCheckout = useCallback(() => {
    if (isMobile) {
      navigate(`/${params.tabletId}/checkout`)
      return
    }

    navigate(`/${params.tabletId}/checkout`, { state: { backgroundLocation: location } })
  }, [isMobile, location, navigate, params.tabletId])

  const navigateToRestaurants = useCallback(
    (replace?: boolean) => {
      navigate('restaurants', { state: { backgroundLocation: location }, replace })
    },
    [location, navigate],
  )

  const handleAdd = useCallback(
    (product: ProductContract) => {
      if (product.productType !== ProductType.LoyaltyCard && instantAddToCart(product.optionSets!)) {
        const cartItem = getCartItemWithPreselectedOptions(product)

        const foundDuplicatedCartItem = findDuplicatedCartItem(cart.items, cartItem)
        if (foundDuplicatedCartItem) {
          dispatch.cart.increaseProductCount(foundDuplicatedCartItem.id)

          return
        }

        dispatch.cart.addToCart(cartItem)

        return
      }

      navigateToProduct({ productId: product.id! })
    },
    [cart.items, dispatch.cart, navigateToProduct],
  )

  const handleContentScroll = useCallback(() => {
    const foundCategory = isMobile
      ? productsListRef.current?.getCategoryByScrollPosition(window.scrollY)
      : desktopProductsListRef.current?.getIdByScrollPosition(
        window.scrollY + DESKTOP_HEADER_HEIGHT + DESKTOP_CATEGORIES_HEIGHT + masterCategoriesHeight,
      )

    const firstCategory = categories?.[0]?.id

    const category = foundCategory ?? firstCategory
    if (!category) {
      return
    }

    setActiveCategoryId(category)

    scrollToCategory(category)
  }, [categories, isMobile, masterCategoriesHeight, scrollToCategory])

  const handleProductIncrease = useCallback(
    (id: string) => {
      dispatch.cart.increaseProductCount(id)
    },
    [dispatch.cart],
  )

  const handleProductDecrease = useCallback(
    (id: string) => {
      if (cart.items[id].count > 1) {
        dispatch.cart.decreaseProductCount(id)
      } else {
        dispatch.cart.removeFromCart(id)
      }
    },
    [cart.items, dispatch.cart],
  )

  const handleCartClick = useCallback(() => {
    if (acceptsAllOrderTypes) {
      navigateToCheckout()
      return
    }

    if (user?.isAcceptingTakeAwayOrders) {
      dispatch.cart.setOrderType(OrderType.TakeAway)
    }

    if (user?.isAcceptingTableOrders) {
      dispatch.cart.setOrderType(OrderType.Table)
    }

    navigateToCheckout()
  }, [
    acceptsAllOrderTypes,
    dispatch.cart,
    navigateToCheckout,
    user?.isAcceptingTableOrders,
    user?.isAcceptingTakeAwayOrders,
  ])

  const handleHistoryClick = () => {
    if (!sessionId) {
      return
    }

    navigate(`/orders/${sessionId}`)
  }

  const handleCategoryClick = (categoryId: string) => {
    setBodyOverflow('visible')

    setCategoriesExpanded(false)

    scrollToProduct(categoryId)
  }

  const handleMasterCategoryClick = (masterCategoryId: string) => {
    setActiveMasterCategoryId(masterCategoryId)

    if (!isMobile || !categoriesExpanded) {
      return
    }

    updateProductsListScrollTopPosition(startPosition)

    categoriesListRef.current?.scrollToTop()
  }

  const handleExpandClick = () => {
    setCategoriesExpanded((prevCategoriesExpanded) => !prevCategoriesExpanded)

    if (!isMobile) {
      return
    }

    if (categoriesExpanded) {
      setBodyOverflow('visible')

      window.scroll({ top: productsListScrollTopPosition })

      return
    }

    updateProductsListScrollTopPosition(window.scrollY)

    scrollToStart()

    setBodyOverflow('hidden')
  }

  const renderFooter = () => {
    return (
      <Footer
        cartSum={isMobile ? getCartTotalPrice(cart) : cartTotalPrice}
        cartItemsCount={Object.keys(cart.items).length}
        intercomEnabled={user?.enableSupportChat}
        showOrderHistory={user?.showOrderHistory}
        CartContentComponent={<CartImgStyled src={CartImg} alt="cart" />}
        onCartClick={handleCartClick}
        onSearchClick={navigateToSearch}
        onHistoryClick={handleHistoryClick}
        onSearchFocus={navigateToSearch}
      />
    )
  }

  useDocScrollTimeout(handleContentScroll)

  useEffect(() => {
    dispatch.app.setBackgroundLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    calculateOrderPrice()
  }, [calculateOrderPrice])

  useEffect(() => {
    if (!categories.length) {
      return
    }

    setActiveCategoryId(categories[0].id!)
  }, [categories])

  useEffect(() => {
    if (!activeMasterCategoryId) {
      return
    }

    if (previousActiveMasterCategoryId.current === activeMasterCategoryId) {
      return
    }

    previousActiveMasterCategoryId.current = activeMasterCategoryId

    scrollToStart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedAndFilteredProducts, scrollToStart])

  useEffect(() => {
    if (!openRestaurants) {
      return
    }

    navigateToRestaurants(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!user || !user.isActive || user.isOpen) {
      return
    }

    openTabletOfflineModal()
  }, [user])

  return (
    <>
      <MainContainer>
        {isMobile ? (
          <>
            <HeaderContainer>
              <Header
                imgsUrl={headerImgsUrl}
                imgHeight={headerImgHeight}
                BgContentComponent={
                  group || hasCustomPages ? (
                    <HeaderBgContentContainer>
                      <div>{!!group && <LocationButton title={headerTitle} onClick={navigateToRestaurants} />}</div>

                      {hasCustomPages && (
                        <div>
                          <IconButton name="menu" onClick={toggleSidebar} />
                        </div>
                      )}
                    </HeaderBgContentContainer>
                  ) : null
                }
                HeaderContentComponent={
                  <HeaderContent onMenuClick={toggleSidebar} onRestaurantClick={navigateToRestaurants} />
                }
              />
            </HeaderContainer>

            {hasMasterCategories && (
              <MasterCategoriesContainer>
                <MasterCategories
                  categories={masterCategories ?? []}
                  activeMasterCategoryId={activeMasterCategoryId}
                  offsetTop={headerImgHeight - MOBILE_HEADER_HEIGHT}
                  onCategoryClick={handleMasterCategoryClick}
                />
              </MasterCategoriesContainer>
            )}

            <CategoriesHorizontalListContainer masterCategoriesHeight={masterCategoriesHeight}>
              <MobileCategoriesHorizontalList
                ref={categoriesListRef}
                categories={categories}
                activeCategoryId={activeCategoryId}
                expanded={categoriesExpanded}
                verticalListHeight={
                  sizes.height -
                  MOBILE_HEADER_HEIGHT -
                  (hasMasterCategories ? MASTER_CATEGORIES_MOBILE_SMALL_HEIGHT : 0) -
                  themeSizes.categoriesListContainerHeight -
                  MOBILE_FOOTER_HEIGHT
                }
                onCategoryClick={handleCategoryClick}
                onExpandClick={handleExpandClick}
              />
            </CategoriesHorizontalListContainer>

            <ProductsList
              ref={productsListRef}
              products={productsWithCartItems}
              headerImgHeight={headerImgHeight}
              hasMasterCategories={hasMasterCategories}
              onAdd={handleAdd}
              onIncrease={handleProductIncrease}
              onDecrease={handleProductDecrease}
              onItemClick={navigateToProduct}
            />
          </>
        ) : (
          <>
            <DesktopHeader
              imgsUrl={headerImgsUrl}
              brandLogoUrl={mediaUrl(user?.receiptImage, { w: 0, h: 88 })}
              onRestaurantClick={navigateToRestaurants}
              onMenuClick={toggleSidebar}
            />

            {hasMasterCategories && (
              <MasterCategoriesContainer>
                <MasterCategories
                  categories={masterCategories ?? []}
                  activeMasterCategoryId={activeMasterCategoryId}
                  offsetTop={DESKTOP_BG_IMG_HEIGHT - DESKTOP_HEADER_HEIGHT}
                  onCategoryClick={handleMasterCategoryClick}
                />
              </MasterCategoriesContainer>
            )}

            <CategoriesHorizontalListContainer masterCategoriesHeight={masterCategoriesHeight}>
              <ContentContainer>
                <DesktopCategoriesHorizontalList
                  ref={desktopCategoriesListRef}
                  categories={categories}
                  activeCategoryId={activeCategoryId}
                  expanded={categoriesExpanded}
                  onCategoryClick={handleCategoryClick}
                  onExpandClick={handleExpandClick}
                />
              </ContentContainer>
            </CategoriesHorizontalListContainer>

            <ContentContainer>
              <DesktopProductsList
                ref={desktopProductsListRef}
                products={productsWithCartItemsAndCategories}
                onAdd={handleAdd}
                onIncrease={handleProductIncrease}
                onDecrease={handleProductDecrease}
                onItemClick={navigateToProduct}
              />
            </ContentContainer>
          </>
        )}

        <FooterContainer>{renderFooter()}</FooterContainer>
      </MainContainer>

      <TabletOfflineModal isOpen={tabletOfflineModalOpen} onClose={closeTabletOfflineModal} />

      {hasCustomPages && <Sidebar visible={sidebarVisible} FooterComponent={renderFooter()} onClose={toggleSidebar} />}
    </>
  )
}

export default Home
