import { ReactNode, memo } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { CustomTypeOptions } from 'i18next'

import ErrorIcon from 'src/assets/icons/error.svg?react'
import Text from './Text'
import PrimaryButton from './PrimaryButton'
import NewModal from './NewModal'
import { useMediaQuery } from 'src/utilities/hooks'

export interface ErrorModalProps {
  errorMsg?: string
  ErrorMsgComponent?: ReactNode
  buttonText?: string
  secondButtonText?: string
  title?: string
  secondButton?: boolean
  onBackClick?: () => void
  onRetryClick?: () => void
  onSecondClick?: () => void
}

interface TransErrorModalMsgProps {
  i18nKey: keyof CustomTypeOptions['resources']['translation']
  values?: Record<string, string>
}

const MainContainer = styled.div`
  border-radius: 1.25rem;
`

const ContentContainer = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 1.375rem;
  padding: 4.5rem 0.75rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding: 2.125rem 0.75rem;
  }
`

const Title = styled(Text as unknown as AnyStyledComponent).attrs({
  style: {
    fontWeight: 400,
    fontSize: '1.5rem',
  },
})``

interface FooterContainerProps {
  secondButton?: boolean
}
const FooterContainer = styled.div<FooterContainerProps>`
  padding: 0.625rem 0.75rem;
  display: ${({ secondButton }) => secondButton ? "flex" : "grid"};
  justify-content: center;
  filter: drop-shadow(0px -2px 14px rgba(0, 0, 0, 0.07));
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  border-radius: 0 0 1.25rem 1.25rem;
`

const ErrorMsg = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'checkoutPageOptionTitle',
  align: 'center',
  color: 'baseItemTextColor',
})`
  white-space: pre-wrap;
`

const Bold = styled.span`
  font-weight: 700;
`

const ErrorIconStyled = styled(ErrorIcon as AnyStyledComponent)`
  color: ${({ theme }) => theme.colors.primaryTextColor};
`

export const TransErrorModalMsg = ({ i18nKey, values }: TransErrorModalMsgProps) => {
  return (
    <ErrorMsg>
      <Trans
        i18nKey={i18nKey}
        values={values}
        components={{
          b: <Bold />,
        }}
      />
    </ErrorMsg>
  )
}

const ErrorModal = (props: ErrorModalProps) => {
  const { t } = useTranslation()

  const { isMobile } = useMediaQuery()

  return (
    <NewModal
      isOpen={!!props.ErrorMsgComponent || !!props.errorMsg}
      width={isMobile ? '100%' : '30rem'}
      showCloseButton
      onRequestClose={props.onBackClick}
    >
      <MainContainer>
        <ContentContainer>
          <ErrorIconStyled />
          <Title type="checkoutPageTitle" align="center" color="baseItemTextColor">
            {props.title ?? t('components.errorModal.title')}
          </Title>
          {props.ErrorMsgComponent ?? (
            <Text type="checkoutPageOptionTitle" align="center" color="baseItemTextColor">
              {props.errorMsg}
            </Text>
          )}
        </ContentContainer>
        <FooterContainer secondButton={props.secondButton}>
          <PrimaryButton textTypeSmall={!!props.secondButtonText} width="12.5rem" onClick={props.onRetryClick} color={props.secondButton ? "secondItemColor" : "firstItemColor"}>
            {props.buttonText ?? t('components.errorModal.retryButtonText')}
          </PrimaryButton>
          {props.secondButton && <><div style={{ paddingLeft: 12 }}></div><PrimaryButton textTypeSmall={!!props.secondButtonText} width="12.5rem" onClick={props.onSecondClick}>
            {props.secondButtonText ?? t('components.errorModal.retryButtonText')}
          </PrimaryButton></>}
        </FooterContainer>
      </MainContainer>
    </NewModal>
  )
}

export default memo(ErrorModal)
