import { memo } from "react"
import { useTranslation } from "react-i18next"
import ErrorModal from "./ErrorModal"

interface RestrictedItemModalProps {
    isOpen: boolean
    changeOrderType?: () => void
    editCartProducts?: () => void
    onBackClick?: () => void
}

const RestrictedItemModal = (props: RestrictedItemModalProps) => {

    const { t } = useTranslation()

    return (
        <ErrorModal
            errorMsg={props.isOpen ? t('pages.checkout.restrictedModal.title') : ''}
            title={""}
            buttonText={t('pages.checkout.restrictedModal.secondaryButton')}
            secondButtonText={t("pages.checkout.restrictedModal.button")}
            onRetryClick={props.editCartProducts}
            onSecondClick={props.changeOrderType}
            onBackClick={props.onBackClick}
            secondButton
        />
    )
}

export default memo(RestrictedItemModal)