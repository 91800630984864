import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { AnyStyledComponent } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isAxiosError } from 'axios'

import NewModal from 'src/components/NewModal'
import Text from 'src/components/Text'
import { useMediaQuery } from 'src/utilities/hooks'
import OptionsList from './components/OptionsList'
import { Dispatch, RootState } from 'src/utilities/store'
import ErrorModal, { ErrorModalProps, TransErrorModalMsg } from 'src/components/ErrorModal'
import { selectCreateOrderData } from 'src/models/cart'
import { ErrorContract } from 'src/types/api'
import { selectTimeSlotsObj } from 'src/models/timeSlots'
import CustomScroll from 'src/components/CustomScroll'
import { selectProductsObj } from 'src/models/catalog'

const ContentContainer = styled(CustomScroll as AnyStyledComponent)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  max-height: calc(32.063rem - 11.25rem);
  box-sizing: border-box;

  @media ${({ theme }) => theme.queries.mobile} {
    padding: 0 1rem 1rem 1rem;
    max-height: calc(24.375rem - 7.75rem);
  }
`

const HeaderContainer = styled.div`
  padding: 1.5rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding: 1rem;
  }
`

const TitleContainer = styled.div`
  padding-bottom: 0rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding-bottom: 0rem;
  }
`

const DeliveryTime = () => {
  const [error, setError] = useState<ErrorModalProps | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { timeSlots, reservedTimeSlot, selectedTimeSlot } = useSelector(
    (state: RootState) => state.timeSlots,
  )

  const createOrderData = useSelector(selectCreateOrderData)
  const timeSlotsObj = useSelector(selectTimeSlotsObj)
  const productsObj = useSelector(selectProductsObj)

  const navigate = useNavigate()

  const { isMobile } = useMediaQuery()

  const { t } = useTranslation()

  const dispatch = useDispatch<Dispatch>()

  const navigateBack = () => {
    navigate(-1)
  }

  const getTimeSlots = async () => {
    try {
      setLoading(true)

      await dispatch.timeSlots.fetchTimeSlots()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error) || !error.response?.data.message) {
        return
      }

      setError({ errorMsg: error.response.data.message })
    } finally {
      setLoading(false)
    }
  }

  const getDisabledProductTitles = (disabledProductIds: string[]) => {
    return disabledProductIds.reduce<string>((titles, disabledProductId) => {
      const title = productsObj[disabledProductId]?.title
      if (title) {
        titles += titles ? `, ${title}` : title
      }

      return titles
    }, '')
  }

  const clearErrorMsg = () => {
    setError(null)
  }

  const releaseTimeSlot = async (id: string) => {
    try {
      await dispatch.timeSlots.releaseTimeSlot(id)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTimeClick = async (id: string) => {
    if (id === selectedTimeSlot?.id) {
      navigateBack()
      return
    }

    try {
      setLoading(true)

      await dispatch.timeSlots.reserveTimeSlot({ id, payload: createOrderData })

      dispatch.timeSlots.setSoonAsPossible(false)

      const timeSlot = timeSlotsObj[id]
      if (timeSlot) {
        dispatch.timeSlots.setSelectedTimeSlot(timeSlot)
      }

      if (reservedTimeSlot) {
        releaseTimeSlot(reservedTimeSlot.id!)
      }

      navigateBack()
    } catch (error) {
      console.error(error)

      if (
        !isAxiosError<ErrorContract>(error) ||
        (!error.response?.data.message && !error.response?.data.internalErrorCode)
      ) {
        return
      }

      switch (error.response.data.internalErrorCode) {
        case 167:
          setError({
            title: t('pages.deliveryTime.backendErrors.167.title'),
            ErrorMsgComponent: (
              <TransErrorModalMsg
                i18nKey="pages.deliveryTime.backendErrors.167.description"
                values={{
                  productTitles: getDisabledProductTitles(
                    error.response.data.orderValidationError?.disabledProductIds ?? [],
                  ),
                }}
              />
            ),
            buttonText: t('pages.deliveryTime.backendErrors.167.buttonText'),
          })
          return
        default:
          setError({ errorMsg: error.response.data.message })
          return
      }
    } finally {
      setLoading(false)
    }
  }

  const handleRetryClick = () => {
    clearErrorMsg()

    getTimeSlots()
  }

  useEffect(() => {
    getTimeSlots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <NewModal
        isOpen
        width={isMobile ? '100%' : '33.75rem'}
        showCloseButton={!isMobile}
        scrollPaddingX={isMobile ? '0.25rem' : '0.5rem'}
        scrollPaddingY={isMobile ? '1rem' : '1.5rem'}
        StickyHeaderComponent={
          <HeaderContainer>
            <TitleContainer>
              <Text
                type={isMobile ? 'productsCategoryTitleMedium' : 'modalTitleMedium'}
                align="center"
                color="baseItemTextColor"
              >
                {t('pages.deliveryTime.title')}
              </Text>
            </TitleContainer>
          </HeaderContainer>
        }
        onRequestClose={navigateBack}
      >
        <ContentContainer>
          <OptionsList disabled={loading} timeSlots={timeSlots} onOptionClick={handleTimeClick} />
        </ContentContainer>
      </NewModal>

      <ErrorModal
        title={error?.title}
        errorMsg={error?.errorMsg}
        buttonText={error?.buttonText}
        ErrorMsgComponent={error?.ErrorMsgComponent}
        onBackClick={clearErrorMsg}
        onRetryClick={handleRetryClick}
      />
    </>
  )
}

export default DeliveryTime
