import { useMediaQuery } from "src/utilities/hooks";
import * as yup from 'yup'
import NewModal from "../../../components/NewModal"
import Error from '../../DynamicPage/components/Error/index'
import EmailAndCompanyInfoCardInput from "src/components/EmailAndCompanyInfoCardInput";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "src/utilities/store";
import { SendReceipEmailContract } from "src/types/api";
import { selectCart } from "src/models/cart";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PrimaryButton from "src/components/PrimaryButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

interface EmailInvoiceModalProps {
    isOpen: boolean;
    orderId?: string;
    onClose?: () => void;
}

const EmailContainer = styled.div`
    padding: 32px 12px 8px 24px;
`
const ButtonContainer = styled.div`
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0px 32px 32px 32px;
`

const SuccessContainer = styled.div`
    max-width: 100%;
    padding-bottom: 8px;
`


const Success = styled(Error).attrs({
    style: {
        color: '#008000',
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 24,
        paddingRight: 8,
    },
})``

type FormSchema = SendReceipEmailContract;

const emailOnlySchema = yup.object({
    orderId: yup.string().required(),
    email: yup.string().email().required(),
})

const schema: yup.ObjectSchema<FormSchema> = yup.object({
    orderId: yup.string().required(),
    email: yup.string().email().required(),
    companyInformation: yup.object().shape({
        name: yup.string().required(),
        code: yup.string().required(),
        vatCode: yup.string(),
        address: yup.string().required(),
    }).optional()
})

const DEFAULT_COMPANY_VALUES: SendReceipEmailContract['companyInformation'] = {
    code: '',
    name: '',
    address: ''
}

const DEFAULT_INVOICE_FORM_VALUES: FormSchema = {
    orderId: '',
    email: '',
    companyInformation: undefined,
}

const EmailInvoiceModal = ({ orderId, isOpen, onClose }: EmailInvoiceModalProps) => {

    const [receiptLoading, setReceiptLoading] = useState(false);
    const [showInvoiceFormSuccessMsg, setShowInvoiceFormSuccessMsg] = useState<boolean>(false)

    const cart = useSelector(selectCart)

    const dispatch = useDispatch<Dispatch>()

    const { t } = useTranslation()

    const { isMobile } = useMediaQuery();

    const emailAndCompanyInfoCardInputRef = useRef<HTMLDivElement>(null)


    const { handleSubmit, formState: { errors }, control, setValue } = useForm<FormSchema>({
        defaultValues: {
            ...DEFAULT_INVOICE_FORM_VALUES,
            orderId
        },
        resolver: cart.isCompany ? yupResolver(schema) : yupResolver(emailOnlySchema),
    })

    useEffect(() => {
        if (orderId) {
            setValue('orderId', orderId);
        }

        if (!isOpen) {
            setShowInvoiceFormSuccessMsg(false)
        }

    }, [orderId, isOpen]);

    const handleEmailReceiptClick = handleSubmit(async (data) => {
        try {
            setReceiptLoading(true)

            await dispatch.invoice.sendReceiptToEmail(data)

            setShowInvoiceFormSuccessMsg(true)

            setReceiptLoading(false)

        } catch (error) {
            console.error(error)

            setReceiptLoading(false)
        }
    })

    return (
        <NewModal onRequestClose={onClose} isOpen={isOpen} width={isMobile ? '100%' : '33.75rem'} height={500}
            showCloseButton style={{ overlay: { zIndex: 999 } }}>
            <EmailContainer>
                <EmailAndCompanyInfoCardInput
                    ref={emailAndCompanyInfoCardInputRef}
                    emailError={!!errors.email}
                    isCompany={cart.isCompany}
                    isModal
                    onIsCompanyClick={(isCompany) => {
                        setValue('companyInformation', isCompany ? DEFAULT_COMPANY_VALUES : undefined)
                        dispatch.cart.updateIsCompany(isCompany);
                    }}
                    control={control}
                    companyInfoErrors={errors.companyInformation}
                />
            </EmailContainer>
            {!!showInvoiceFormSuccessMsg && (
                <SuccessContainer>
                    <Success msg={cart?.isCompany ? t('pages.dynamicPage.components.invoiceForm.successMsg') : t('pages.dynamicPage.components.receipt.successMsg')} />
                </SuccessContainer>
            )}
            <ButtonContainer>
                <PrimaryButton onClick={handleEmailReceiptClick} disabled={receiptLoading}>{t("pages.status.sendEmailButton")}</PrimaryButton>
            </ButtonContainer>

        </NewModal >
    )
}

export default EmailInvoiceModal;