import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'

import { RootModel } from '.'
import {
  CompanyInformationContract,
  CreateOrderContract,
  OrderContract,
  OrderPriceContract,
  OrderType,
  PaymentType,
  RateOrderContract,
  VerifyLoyaltyCodeContract,
  VerifyLoyaltyCodeResultContract,
} from 'src/types/api'
import api from 'src/utilities/api'
import { Cart, selectCart, selectCartTakeAwayDiscountAmount } from './cart'
import { store } from 'src/utilities/store'
import { getCartTipsAmount, getGroupHost } from 'src/utilities/functions'

interface OrdersState {
  orderPrice: OrderPriceContract | null
  loyaltyCode: VerifyLoyaltyCodeResultContract | null
}

const initialState: OrdersState = {
  orderPrice: null,
  loyaltyCode: null,
}

export const orders = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateOrderPrice(state, orderPrice: OrderPriceContract) {
      state.orderPrice = orderPrice
    },
    setLoyaltyCode(state, loyaltyCode: VerifyLoyaltyCodeResultContract) {
      state.loyaltyCode = loyaltyCode
    },
    clearOrderPrice(state) {
      state.orderPrice = initialState.orderPrice
    },
    clearLoyaltyCode(state) {
      state.loyaltyCode = initialState.loyaltyCode
    },
    resetState(state) {
      state.orderPrice = initialState.orderPrice
      state.loyaltyCode = initialState.loyaltyCode
    },
  },
  effects: (dispatch) => ({
    async calculateOrderPrice(
      payload: { userVisiblePrice: number; cart?: Cart; promoCode?: string; loyaltyCode?: string },
      state,
    ) {
      const user = state.profile.user
      const reservedTimeSlot = state.timeSlots.reservedTimeSlot
      const cart = payload.cart ?? selectCart(store.getState())
      const cartTakeAwayDiscountAmount = selectCartTakeAwayDiscountAmount(store.getState(), cart)

      const data: CreateOrderContract = {
        type: cart.orderType,
        commentToKitchen: cart.comment,
        promoCode: payload.promoCode ?? cart.promoCode,
        userVisiblePrice: payload.userVisiblePrice,
        serviceFeeAmount: user?.serviceFee,
        products: Object.values(cart.items).map((item) => ({
          id: item.product.id!,
          price: item.product.price,
          count: item.count,
          optionSets: Object.values(item?.optionSets ?? {}).map((parentOptionSet) => ({
            id: parentOptionSet.optionSet.id,
            order: parentOptionSet.optionSet.order,
            options: Object.values(parentOptionSet?.options ?? {}).map((parentOption) => ({
              id: parentOption.option.id!,
              price: parentOption.option.price,
              count: parentOption.count,
              loyaltyEmail: item?.loyaltyEmail,
              productType: parentOption?.option?.productType,
              optionSets: Object.values(item?.childOptionSets?.[parentOptionSet?.optionSet?.id!] ?? {}).map(
                (childOptionSet) => ({
                  id: childOptionSet.optionSet.id,
                  order: childOptionSet.optionSet.order,
                  options: Object.values(childOptionSet?.options ?? {}).map((childOption) => ({
                    id: childOption.option.id!,
                    price: childOption.option.price,
                    count: childOption.count,
                    loyaltyEmail: item?.loyaltyEmail,
                    productType: childOption?.option?.productType,
                  })),
                }),
              ),
            })),
          })),
          loyaltyEmail: item?.loyaltyEmail,
          productType: item?.product?.productType,
        })),
        pagerNumber: cart.pagerNumber,
        invoiceEmail: cart.invoiceEmail,
        guestIdentification: cart.guestIdentification,
        timeSlotReservationId: reservedTimeSlot?.id,
        userPhoneNumber: cart.phoneNumber,
        tipAmount: getCartTipsAmount(cart),
        loyaltyCode: payload.loyaltyCode ?? state.orders.loyaltyCode?.loyaltyCode,
        takeAwayDiscountAmount: cart.orderType === OrderType.TakeAway ? cartTakeAwayDiscountAmount : undefined,
      }

      const response: AxiosResponse<OrderPriceContract> = await api.post('/qr/orders/calculate', data)

      dispatch.orders.updateOrderPrice(response.data)

      return response.data
    },
    async createOrder(
      payload: {
        userVisiblePrice: number
        cart: Cart
        promoCode: string
        paymentType: PaymentType
        isUserAgeConfirmed: boolean
        clientCode?: number
        loyaltyCode?: string
      },
      state,
    ) {
      const user = state.profile.user
      const reservedTimeSlot = state.timeSlots.reservedTimeSlot
      const cartTakeAwayDiscountAmount = selectCartTakeAwayDiscountAmount(store.getState(), payload.cart)

      const data: CreateOrderContract = {
        type: payload.cart.orderType,
        commentToKitchen: payload.cart.comment,
        promoCode: payload.promoCode,
        userVisiblePrice: payload.userVisiblePrice,
        serviceFeeAmount: user?.serviceFee,
        paymentType: payload.paymentType,
        isUserAgeConfirmed: payload.isUserAgeConfirmed,
        products: Object.values(payload.cart.items).map((item) => ({
          id: item.product.id!,
          price: item.product.price,
          count: item.count,
          optionSets: Object.values(item?.optionSets ?? {}).map((parentOptionSet) => ({
            id: parentOptionSet.optionSet.id,
            order: parentOptionSet.optionSet.order,
            options: Object.values(parentOptionSet?.options ?? {}).map((parentOption) => ({
              id: parentOption.option.id!,
              price: parentOption.option.price,
              count: parentOption.count,
              loyaltyEmail: item?.loyaltyEmail,
              productType: parentOption?.option?.productType,
              optionSets: Object.values(item?.childOptionSets?.[parentOptionSet?.optionSet?.id!] ?? {}).map(
                (childOptionSet) => ({
                  id: childOptionSet.optionSet.id,
                  order: childOptionSet.optionSet.order,
                  options: Object.values(childOptionSet?.options ?? {}).map((childOption) => ({
                    id: childOption.option.id!,
                    price: childOption.option.price,
                    count: childOption.count,
                    loyaltyEmail: item?.loyaltyEmail,
                    productType: childOption?.option?.productType,
                  })),
                }),
              ),
            })),
          })),
          loyaltyEmail: item?.loyaltyEmail,
          productType: item?.product?.productType,
        })),
        clientCode: payload.clientCode,
        loyaltyCode: payload.loyaltyCode,
        pagerNumber: payload.cart.pagerNumber,
        invoiceEmail: payload.cart.invoiceEmail,
        guestIdentification: payload.cart.guestIdentification,
        timeSlotReservationId: reservedTimeSlot?.id,
        userPhoneNumber: payload.cart.phoneNumber,
        companyInformation: payload.cart.isCompany
          ? (payload.cart.companyInfo as CompanyInformationContract)
          : undefined,
        tipAmount: getCartTipsAmount(payload.cart),
        takeAwayDiscountAmount: payload.cart.orderType === OrderType.TakeAway ? cartTakeAwayDiscountAmount : undefined,
      }

      const response: AxiosResponse<OrderContract> = await api.post('/qr/orders', data, {
        headers: {
          GroupHost: getGroupHost(),
        },
      })

      return response.data
    },
    async fetchOrder(payload: { id: string; paymentToken?: string | null; orderToken?: string | null }) {
      const params: { paymentToken?: string; 'order-token'?: string } = {}
      if (payload.paymentToken) {
        params.paymentToken = payload.paymentToken
      }
      if (payload.orderToken) {
        params['order-token'] = payload.orderToken
      }
      const response: AxiosResponse<OrderContract> = await api.get(`/qr/orders/${payload.id}`, { params })

      return response.data
    },
    async checkLoyaltyCode(payload: VerifyLoyaltyCodeContract) {
      const { data }: AxiosResponse<VerifyLoyaltyCodeResultContract> = await api.post('/qr/checkLoyaltyCode', payload)

      dispatch.orders.setLoyaltyCode(data)

      return data
    },
    async rateOrder({ orderId, ...data }: RateOrderContract & { orderId: string }) {
      await api.post(`/qr/orders/${orderId}/rate`, data)
    },
    async fetchOrders(sessionId: string) {
      const res: AxiosResponse<OrderContract[]> = await api.get(`/qr/orders/session/${sessionId}`)

      return res.data
    },
  }),
})
