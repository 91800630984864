import { HTMLAttributes, memo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import Text from './Text'
import { RootState } from 'src/utilities/store'
import { selectPriceWithCurrency } from 'src/models/profile'
import { useMediaQuery } from 'src/utilities/hooks'

interface AdditionalCartItemProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  price?: number
  hasShadow?: boolean
  isDiscount?: boolean
}

interface MainContainerProps {
  hasShadow?: boolean
  isMobile?: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  padding: ${({ isMobile, hasShadow }) => isMobile && !hasShadow ? "0rem" : "0.75rem"};
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  ${({ hasShadow, theme }) =>
    hasShadow &&
    `
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
    border-radius: 0.563rem;
    border: 1px solid ${theme.colors.baseItemColor};
  `}
`

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1.25rem;
  align-items: center;
`

const AdditionalCartItem = ({ title, price, hasShadow, isDiscount, ...props }: AdditionalCartItemProps) => {
  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, price))

  const { isMobile } = useMediaQuery()

  return (
    <MainContainer hasShadow={hasShadow} isMobile={isMobile} {...props}>
      <ContentContainer>
        <Text color="baseItemTextColor" type={isMobile ? "productPageOptionName" : "checkoutPageOptionTitle"}>{title}</Text>
        <Text color="baseItemTextColor" type={isMobile ? "productPageOptionName" : "checkoutPageOptionTitle"}>{isDiscount ? "-" + priceWithCurrency : priceWithCurrency}</Text>
      </ContentContainer>
    </MainContainer>
  )
}

export default memo(AdditionalCartItem)
