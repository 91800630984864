import styled, { AnyStyledComponent } from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import NewModal from 'src/components/NewModal'
import SelectButton from 'src/components/SelectButton'
import { useMediaQuery, useNearestRestaurant } from 'src/utilities/hooks'
import Text from 'src/components/Text'
import { Dispatch, RootState } from 'src/utilities/store'
import CustomScroll from 'src/components/CustomScroll'
import NearestRestaurantButton from 'src/components/NearestRestaurantButton'
import ErrorModal from 'src/components/ErrorModal'
import { QrVenueContract } from 'src/types/api'
import { getVenueUrlData, navigateToExternalUrl } from 'src/utilities/functions'
import { IS_STAGING } from 'src/utilities/constants'
import RestaurantModal from 'src/components/RestaurantModal'

const ContentContainer = styled(CustomScroll as AnyStyledComponent)`
  padding: 1.5rem 3rem;
  box-sizing: border-box;
  max-height: 50rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding: 1rem 2rem;
    max-height: 29rem;
  }
`

const RestaurantsContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;

  @media ${({ theme }) => theme.queries.mobile} {
    row-gap: 1rem;
  }
`

const HeaderContainer = styled.div`
  padding: 1.5rem 3rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding: 1rem 2rem;
  }
`

const Restaurants = () => {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [activeRestaurantId, setActiveRestaurantId] = useState<string>()

  const venues = useSelector((state: RootState) => state.group.group?.venues)

  const { isMobile } = useMediaQuery()

  const params = useParams<{ tabletId: string }>()

  const { t } = useTranslation()

  const { loading, getNearestRestaurant } = useNearestRestaurant()

  const dispatch = useDispatch<Dispatch>()

  const clearErrorMsg = () => {
    setErrorMsg('')
  }

  const navigateTo = (venue?: QrVenueContract) => {
    if (!venue) {
      return
    }

    const venueUrlData = getVenueUrlData(venue)
    if (!venueUrlData) {
      return
    }

    if (params.tabletId !== venueUrlData.tabletId) {
      dispatch.profile.setUserLoading(true)
    }

    if (IS_STAGING) {
      dispatch.app.setTestGroupHost(venueUrlData.domain)
    }

    dispatch.home.setOpenRestaurants(false)

    navigateToExternalUrl(venueUrlData.url)
  }

  const handleNearestRestaurantClick = async () => {
    try {
      const nearestRestaurant = await getNearestRestaurant()

      setActiveRestaurantId(nearestRestaurant.tabletId)
    } catch (error) {
      // @ts-expect-error
      setErrorMsg(t(`geolocationErrors.${error as number}`))
    }
  }

  const handleRestaurantModalContinue = (venue: QrVenueContract) => {
    navigateTo(venue)
  }

  const handleRestaurantModalClose = () => {
    setActiveRestaurantId(undefined)
  }

  return (
    <>
      <NewModal
        isOpen
        width={isMobile ? '100%' : '33.75rem'}
        StickyHeaderComponent={
          <HeaderContainer>
            <Text
              type={isMobile ? 'productsCategoryTitleMedium' : 'modalTitleMedium'}
              align="center"
              color="baseItemTextColor"
            >
              {t('pages.restaurants.title')}
            </Text>
          </HeaderContainer>
        }
        onRequestClose={() => { }}
      >
        <ContentContainer paddingX={isMobile ? '0.75rem' : '1.25rem'} paddingY={isMobile ? '1rem' : '1.5rem'}>
          <RestaurantsContainer>
            <NearestRestaurantButton disabled={loading} onClick={handleNearestRestaurantClick}>
              {t('pages.restaurants.nearestRestaurantTitle')}
            </NearestRestaurantButton>

            {(venues ?? []).map((venue) =>
              <SelectButton key={venue.id} useHover onClick={() => navigateTo(venue)}>
                {venue.restaurantDisplayTitle}
              </SelectButton>
            )}
          </RestaurantsContainer>
        </ContentContainer>
      </NewModal >

      <ErrorModal
        errorMsg={errorMsg}
        buttonText={t('pages.restaurants.errorModalButtonText')}
        onBackClick={clearErrorMsg}
        onRetryClick={clearErrorMsg}
      />

      <RestaurantModal
        restaurantId={activeRestaurantId}
        onContinue={handleRestaurantModalContinue}
        onClose={handleRestaurantModalClose}
      />
    </>
  )
}

export default Restaurants
