import { ReactNode, useCallback } from 'react'
import RModal, { Styles } from 'react-modal'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { setBodyOverflow } from 'src/utilities/functions'

enum Alignment {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}

export interface ModalProps {
  isOpen: boolean
  alignment?: keyof typeof Alignment
  style?: Styles
  children?: ReactNode
  onRequestClose?: () => void
}

interface ModalStyledProps {
  style?: Styles
  alignment: string
}

const ModalStyled = styled(RModal).attrs<ModalStyledProps>(({ style, alignment, theme }) => ({
  style: {
    overlay: {
      backgroundColor: 'rgba(217, 217, 217, 0.40)',
      backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)',
      zIndex: 999,
      display: 'flex',
      alignItems: alignment,
      ...style?.overlay,
    },
    content: {
      maxWidth: theme.sizes.maxPageContainerWidth,
      width: '100%',
      margin: '0 auto',
      ...style?.content,
    },
  },
})) <ModalStyledProps>``

const Modal = (props: ModalProps) => {
  const navigate = useNavigate()

  const getAlignment = useCallback(() => {
    switch (props.alignment) {
      case Alignment.center:
        return 'center'
      case Alignment.bottom:
        return 'flex-end'
      default:
        return 'flex-start'
    }
  }, [props.alignment])

  const handleRequestClose = useCallback(() => {
    if (!props.onRequestClose) {
      navigate(-1)
    } else {
      props.onRequestClose()
    }
  }, [navigate, props])

  const handleAfterOpen = () => {
    setBodyOverflow('hidden')
  }

  const handleAfterClose = () => {
    setBodyOverflow('visible')
  }

  return (
    <ModalStyled
      isOpen={props.isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      style={props.style}
      alignment={getAlignment()}
      //@ts-ignore
      children={props.children}
      shouldFocusAfterRender={false}
      onRequestClose={handleRequestClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
    />
  )
}

export default Modal
