import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'

import { CartItem } from 'src/models/cart'
import CartProduct, { CartProductRef } from './CartProduct'

export interface CartProductsRef {
  getFirstRestrictedProductOffsetTop: () => number | null
}

interface CartProductsProps {
  cartItems: CartItem[]
  editing?: boolean
  cartProductsWithAgeToComply?: { [productId: string]: number }
  showErrors?: boolean
  hasShadow?: boolean
  restrictedErrorMsg?: string
  onAdd?: (id: string) => void
  onRemove?: (id: string) => void
}

const Container = styled.div`
  display: grid;
  row-gap: 0.75rem;
`

const CartProducts = forwardRef<CartProductsRef, CartProductsProps>((props, ref) => {
  const firstRestrictedProductOffsetTop = useRef<number | null>(null)

  const showError = useCallback(
    (productId: string) => {
      if (!props.showErrors || !props.cartProductsWithAgeToComply) {
        return false
      }

      const ages = Object.values(props.cartProductsWithAgeToComply)

      return props.cartProductsWithAgeToComply[productId] === ages[0]
    },
    [props.cartProductsWithAgeToComply, props.showErrors],
  )

  const setFirstRestrictedProductOffsetTop = (itemRef: CartProductRef | null, index: number) => {
    if (index === 0) {
      firstRestrictedProductOffsetTop.current = null
    }

    if (!itemRef || !itemRef.hasRestrictedError() || typeof firstRestrictedProductOffsetTop.current === 'number') {
      return
    }

    const elOffsetTop = itemRef.getEl()?.offsetTop
    if (typeof elOffsetTop !== 'number') {
      return
    }

    firstRestrictedProductOffsetTop.current = elOffsetTop
  }

  useImperativeHandle(
    ref,
    () => {
      return { getFirstRestrictedProductOffsetTop: () => firstRestrictedProductOffsetTop.current }
    },
    [],
  )

  return (
    <Container>
      {props.cartItems.map((cartItem, index) => (
        <CartProduct
          key={cartItem.id}
          ref={(itemRef) => setFirstRestrictedProductOffsetTop(itemRef, index)}
          cartItem={cartItem}
          editing={props.editing}
          showError={showError(cartItem.product.id!)}
          hasShadow={props.hasShadow}
          restrictedErrorMsg={props.restrictedErrorMsg}
          onAdd={props.onAdd}
          onRemove={props.onRemove}
        />
      ))}
    </Container>
  )
})

export default memo(CartProducts)
