import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { validate as validateuuidv4 } from 'uuid'
import { useCallback } from 'react'

import { Dispatch, RootState } from 'src/utilities/store'
import {
  getHost,
  getMainHost,
  getMatchTabletId,
  getVenueUrlData,
  hasSubdomains,
  isSubdomainHost,
} from 'src/utilities/functions'
import { IS_STAGING } from 'src/utilities/constants'
import { CustomPageType } from 'src/types/api'

const NavigateToTablet = ({ tabletId }: { tabletId: string }) => {
  return <Navigate to={`/${tabletId}`} replace />
}

const NavigateToMain = () => {
  return <Navigate to="/" replace />
}

const ProtectedRoutes = () => {
  const group = useSelector((state: RootState) => state.group.group)
  const user = useSelector((state: RootState) => state.profile.user)
  const websiteData = useSelector((state: RootState) => state.website.websiteData)
  const openRestaurants = useSelector((state: RootState) => state.home.openRestaurants)

  const dispatch = useDispatch<Dispatch>()

  const navigateToMain = useCallback(() => {
    if (IS_STAGING) {
      dispatch.app.setTestGroupHost(getMainHost())

      window.location.replace(window.location.origin)

      return
    }

    window.location.replace(getMainHost())
  }, [dispatch.app])

  const setRestaurantsOpen = useCallback(() => {
    dispatch.home.setOpenRestaurants(true)
  }, [dispatch.home])

  if (!user) {
    if (window.location.pathname !== '/') {
      return <NavigateToMain />
    }

    return <Outlet />
  }

  const matchTabletId = getMatchTabletId()
  if (matchTabletId && validateuuidv4(matchTabletId)) {
    if (hasSubdomains() && getHost() === getMainHost() && !user.ignoreRestaurantsModal && !openRestaurants) {
      setRestaurantsOpen()

      return <NavigateToMain />
    }

    if (!user.isActive && websiteData?.pages?.length) {
      const closedVenuePlaceholderPage = websiteData.pages.find(
        (page) => page.type === CustomPageType.ClosedVenuePlaceholder,
      )
      if (!closedVenuePlaceholderPage?.path) {
        return <Outlet />
      }

      return <NavigateToTablet tabletId={closedVenuePlaceholderPage.path} />
    }
  }

  if (window.location.pathname === '/' && group?.venues?.length) {
    const host = getHost()
    if (isSubdomainHost(host)) {
      const foundVenue = group.venues.find((venue) => venue.domain === host)
      if (!foundVenue) {
        navigateToMain()
        return <Outlet />
      }

      const venueUrlData = getVenueUrlData(foundVenue)
      if (!venueUrlData) {
        navigateToMain()
        return <Outlet />
      }

      return <NavigateToTablet tabletId={venueUrlData.tabletId} />
    }

    const firstVenueTabletId = group.venues[0].tabletId
    if (!firstVenueTabletId) {
      return <Outlet />
    }

    if (group.venues.length === 1) {
      return <NavigateToTablet tabletId={firstVenueTabletId} />
    }

    setRestaurantsOpen()

    return <NavigateToTablet tabletId={firstVenueTabletId} />
  }

  return <Outlet />
}

export default ProtectedRoutes
