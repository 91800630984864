import { ButtonHTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

import Text from 'src/components/Text'
import { useMediaQuery } from 'src/utilities/hooks'

interface OptionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  RightComponent?: ReactNode
  selected?: boolean
  primary?: boolean
}

const MainContainer = styled.div`
  height: 3.125rem;
  display: grid;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 34px;
  position: relative;

  @media ${({ theme }) => theme.queries.mobile} {
    height: 2.25rem;
    border-radius: 0.938rem;
  }
`

interface ButtonProps {
  selected?: boolean
  primary?: boolean
}



const Button = styled.button<ButtonProps>`
  border: none;
  padding: 0;
  border-radius: inherit;
  overflow: hidden;
  background-color: ${({ theme, selected, primary }) => selected ? theme.colors.firstItemColor : primary ? theme.colors.fourthItemColor : theme.colors.baseItemColor};
  :enabled {
    cursor: pointer;
  }
`

const RightContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  display: grid;
  align-content: center;
`

const OptionButton = ({ children, RightComponent, selected, primary, ...props }: OptionButtonProps) => {
  const { isMobile } = useMediaQuery()

  return (
    <MainContainer>
      <Button selected={selected} type="button" primary={primary} {...props}>
        <Text align="center" type={isMobile ? 'checkoutPageOptionTitle' : 'buttonText'} color={!!RightComponent ? "primaryTextColor" : "baseItemTextColor"}>
          {children}
        </Text>
      </Button>

      {!!RightComponent && <RightContainer>{RightComponent}</RightContainer>}
    </MainContainer>
  )
}

export default OptionButton
