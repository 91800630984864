import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/react'
import { Helmet } from 'react-helmet-async'

import PageLoader from 'src/components/PageLoader'
import { Dispatch, RootState } from 'src/utilities/store'
import Home from './Home'
import Product from './Product'
import Checkout from './Checkout'
import DeliveryTime from './DeliveryTime'
import Comment from './Comment'
import Discount from './Discount'
import Status from './Status'
import Search from './Search'
import { QrUserProfileContract } from 'src/types/api'
import Appraisals from './Appraisals'
import Orders from './Orders'
import Restaurants from './Restaurants'
import { useTabletId } from 'src/utilities/hooks'
import DynamicPageSuccess from './DynamicPageSuccess'
import { useTheme } from 'src/utilities/theme'
import { selectOnlyMenu } from 'src/models/profile'
import { initGTM } from 'src/utilities/gtm'
import ProtectedRoutes from 'src/components/ProtectedRoutes'
import DynamicRoutes from 'src/components/DynamicRoutes'
import { updateTranslations } from 'src/utilities/i18n'
import { mediaUrl } from 'src/utilities/functions'

interface LocationState {
  backgroundLocation?: Location
}

const RouterRoutes = () => {
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [translationsLoading, setTranslationsLoading] = useState<boolean>(false)

  const { sessionId, lastTabletId, testGroupHost } = useSelector((state: RootState) => state.app)
  const { user, userLoading } = useSelector((state: RootState) => state.profile)
  const group = useSelector((state: RootState) => state.group.group)
  const websiteData = useSelector((state: RootState) => state.website.websiteData)

  const onlyMenu = useSelector(selectOnlyMenu)

  const dispatch = useDispatch<Dispatch>()

  const tabletId = useTabletId()
  const location = useLocation()
  const locationState = location.state as LocationState | null

  const { i18n } = useTranslation()

  const { boot } = useIntercom()

  const theme = useTheme()

  const loading = useMemo(
    () => dataLoading || theme.loading || userLoading || translationsLoading,
    [dataLoading, theme.loading, translationsLoading, userLoading],
  )

  const bootIntercom = (user: QrUserProfileContract, sessionId: string) => {
    boot({
      userId: sessionId,
      hideDefaultLauncher: true,
      customAttributes: {
        tablet_id: user.tablet?.id,
        venue_name: user.restaurantTitle,
      },
    })
  }

  const setSessionId = () => {
    dispatch.app.setSessionId(uuidv4())
  }

  const fetchGroup = async () => {
    try {
      return await dispatch.group.fetchGroup()
    } catch (error) {
      console.error(error)
    }
  }

  const fetchUser = async () => {
    dispatch.profile.setUserLoading(true)

    try {
      await dispatch.profile.fetchUser()
    } catch (error) {
      console.error(error)
    } finally {
      dispatch.profile.setUserLoading(false)
    }
  }

  const fetchWebsiteData = async (groupId: string) => {
    try {
      await dispatch.website.fetchWebsiteData(groupId)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAndUpdateTranslations = async () => {
    try {
      setTranslationsLoading(true)

      const translations = await dispatch.translations.fetchTranslations()

      updateTranslations(translations)
    } catch (error) {
      console.error(error)
    } finally {
      setTranslationsLoading(false)
    }
  }

  const fetchCatalog = async () => {
    try {
      await dispatch.catalog.fetchCatalog()
    } catch (error) {
      console.error(error)
    }
  }

  const fetchTimeSlots = async () => {
    try {
      await dispatch.timeSlots.fetchTimeSlots()
    } catch (error) {
      console.error(error)
    }
  }

  const initDataFetch = async () => {
    setDataLoading(true)

    const group = await fetchGroup()

    const promises: Promise<void>[] = []

    if (group?.id) {
      promises.push(fetchWebsiteData(group.id))
    }

    if (tabletId) {
      promises.concat([fetchUser(), fetchAndUpdateTranslations(), fetchCatalog(), fetchTimeSlots()])
    }

    await Promise.all(promises)

    setDataLoading(false)
  }

  useEffect(() => {
    initDataFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabletId, testGroupHost, i18n.language])

  useEffect(() => {
    if (sessionId) {
      return
    }

    setSessionId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  useEffect(() => {
    if (!user || !user.enableSupportChat || !sessionId) {
      return
    }

    bootIntercom(user, sessionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, user])

  useEffect(() => {
    if (!tabletId) {
      return
    }

    dispatch.app.setLastTabletId(tabletId)

    if (!lastTabletId || lastTabletId === tabletId) {
      return
    }

    dispatch.cart.clearCart()
  }, [dispatch.app, dispatch.cart, lastTabletId, tabletId])

  useEffect(() => {
    if (user && onlyMenu) {
      dispatch.cart.clearCart()
    }
  }, [dispatch.cart, onlyMenu, user])

  useEffect(() => {
    const gtmId = websiteData?.gtmId

    if (!tabletId || !gtmId) {
      return
    }

    initGTM({
      gtmId,
      dataLayer: {
        tabletId,
      },
    })
  }, [tabletId, websiteData?.gtmId])

  const setSentry = (id: string) => {
    Sentry.setUser({ id })
  }

  useEffect(() => {
    if (!sessionId) {
      return
    }

    setSentry(sessionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  if (loading) {
    return <PageLoader />
  }

  return (
    <>
      <Helmet>
        {!!websiteData?.favicon && (
          <>
            <meta property="og:image" content={mediaUrl(websiteData.favicon, { fit: 'crop', w: 200, h: 200 })} />
            <link rel="icon" href={mediaUrl(websiteData.favicon, { fit: 'crop', w: 48, h: 48 })} />
            <link rel="apple-touch-icon" href={mediaUrl(websiteData.favicon, { fit: 'crop', w: 180, h: 180 })} />
          </>
        )}
        {!!websiteData?.pageDescription && (
          <>
            <meta property="og:description" content={websiteData.pageDescription} />
            <meta name="description" content={websiteData.pageDescription} />
          </>
        )}
        {!!websiteData?.pageTitle && (
          <>
            <meta property="og:title" content={websiteData.pageTitle} />
            <title>{websiteData.pageTitle}</title>
          </>
        )}
      </Helmet>

      <Routes location={locationState?.backgroundLocation ?? location}>
        <Route element={<ProtectedRoutes />}>
          <Route element={<DynamicRoutes />}>
            <Route path="/" element={group ? <Restaurants /> : null} />
            <Route path=":tabletId" element={<Home />} />
            {!onlyMenu && (
              <>
                <Route path=":tabletId/checkout" element={<Checkout />} />
                <Route path=":tabletId/appraisals" element={<Appraisals />}>
                  <Route path=":appraisalId" element={<Appraisals />} />
                </Route>
                <Route path=":tabletId/:orderId/status" element={<Status />} />
              </>
            )}
            <Route path="/orders/:sessionId" element={<Orders />} />
            <Route path=":tabletId/success" element={<DynamicPageSuccess />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Route>
      </Routes>

      {!!locationState?.backgroundLocation && (
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route element={<DynamicRoutes />}>
              {!!group && <Route path=":tabletId/restaurants" element={<Restaurants />} />}
              {!onlyMenu && (
                <>
                  {!!user?.enableTimeSlots && (
                    <Route path=":tabletId/checkout/delivery-time" element={<DeliveryTime />} />
                  )}
                  <Route path=":tabletId/checkout" element={<Checkout />} />
                  <Route path=":tabletId/checkout/comment" element={<Comment />} />
                  <Route path=":tabletId/checkout/discount" element={<Discount />} />
                  <Route path=":tabletId/:orderId/status" element={<Status />} />
                </>
              )}
              <Route path=":tabletId/search" element={<Search />} />
              <Route path=":tabletId/:productId" element={<Product />} />
            </Route>
          </Route>
        </Routes>
      )}
    </>
  )
}

const Navigation = () => {
  return (
    <BrowserRouter>
      <RouterRoutes />
    </BrowserRouter>
  )
}

export default Navigation
