import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import styled from 'styled-components'
import { padStart } from 'lodash'

import { useTheme } from 'src/utilities/theme'
import IconActionButton from 'src/components/IconActionButton'
import { useMediaQuery } from 'src/utilities/hooks'
import Text from 'src/components/Text'

interface ChooseTimeButtonProps {
  selected?: boolean
  error?: boolean
  imgSrc?: string
  title?: string
  durationInSeconds?: number
  showTimer?: boolean
  onClick?: () => void
  onDurationComplete?: () => void
}

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
`

const ChooseTimeButton = ({
  selected,
  error,
  imgSrc,
  title,
  durationInSeconds = 0,
  showTimer,
  onClick,
  onDurationComplete,
}: ChooseTimeButtonProps) => {
  const { theme } = useTheme()

  const { isMobile } = useMediaQuery()

  return (
    <MainContainer>
      {!!showTimer && (
        <CountdownCircleTimer
          key={durationInSeconds}
          isPlaying
          duration={durationInSeconds}
          // @ts-expect-error
          colors={theme.colors.firstItemColor}
          size={50}
          strokeWidth={2}
          updateInterval={1}
          onComplete={onDurationComplete}
        >
          {({ remainingTime }) => {
            const minutes = Math.floor(remainingTime / 60)
            const seconds = remainingTime % 60

            return (
              <Text type={isMobile ? 'productPrice' : 'buttonText'} color="baseItemTextColor">
                {minutes}:{padStart(String(seconds), 2, '0')}
              </Text>
            )
          }}
        </CountdownCircleTimer>
      )}

      <IconActionButton selected={selected} error={error} imgSrc={imgSrc} onClick={onClick}>
        {title}
      </IconActionButton>
    </MainContainer>
  )
}

export default ChooseTimeButton
