import i18n, { LanguageDetectorAsyncModule } from 'i18next'
import { initReactI18next } from 'react-i18next'
import _ from 'lodash'

import lt from 'src/assets/translations/lt.json'
import en from 'src/assets/translations/en.json'
import ru from 'src/assets/translations/ru.json'
import fr from 'src/assets/translations/fr.json'
import tr from 'src/assets/translations/tr.json'
import lv from 'src/assets/translations/lv.json'
import { LanguageCode, TranslationContract } from 'src/types/api'
import { store, waitForPersistor } from './store'
import { getDefautlLanguage } from './functions'

const resources = { lt, en, ru, fr, tr, lv }

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  init: () => { },
  detect: async () => {
    await waitForPersistor()

    const languageCode = store.getState().app.languageCode
    if (languageCode) {
      return languageCode
    }

    return await getDefautlLanguage()
  },
  cacheUserLanguage: (languageCode: string) => {
    store.dispatch.app.setLanguageCode(languageCode)
  },
}

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: LanguageCode.En,
    interpolation: {
      escapeValue: false,
    },
  })

export const updateTranslations = (translations: TranslationContract[]) => {
  const updatedResources = _.cloneDeep(resources)

  translations.forEach((translation) => {
    if (!translation.term) {
      return
    }

    Object.entries(translation.translations ?? {}).forEach(([key, value]) => {
      const term = translation.term!

      const path = `${key}.translation['${term}']`

      if (!_.has(updatedResources, path)) {
        return
      }

      _.set(updatedResources, path, value)
    })
  })

  Object.entries(updatedResources).forEach(([lng, resource]) => {
    i18n.addResourceBundle(lng, 'translation', resource.translation, true, true)
  })
}

export default i18n
