import { memo } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { useSelector } from 'react-redux'

import Text from 'src/components/Text'
import { RootState } from 'src/utilities/store'
import { selectPriceWithCurrency } from 'src/models/profile'

interface InfoItemProps {
  title: string
  description?: string
  price?: number
}

const MainContainer = styled.div`
  display: grid;
  row-gap: 0.5rem;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled(Text as unknown as AnyStyledComponent).attrs({
  style: { fontWeight: 700 },
})``

const InfoItem = (props: InfoItemProps) => {
  const priceWithCurrency = useSelector((state: RootState) => selectPriceWithCurrency(state, props.price))

  return (
    <MainContainer>
      <TitleContainer>
        <Title type="checkoutPageOptionTitle" color="baseItemTextColor">
          {props.title}
        </Title>

        {!!props.price && (
          <Text type="checkoutPageOptionTitle" color="baseItemTextColor">
            {priceWithCurrency}
          </Text>
        )}
      </TitleContainer>

      {!!props.description && (
        <Text type="checkoutPageOptionDescription" color="secondaryTextColor">
          {props.description}
        </Text>
      )}
    </MainContainer>
  )
}

export default memo(InfoItem)
