import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { MapCameraProps, MarkerProps } from '@vis.gl/react-google-maps'

import NearestRestaurantButton from 'src/components/NearestRestaurantButton'
import { useNearestRestaurant } from 'src/utilities/hooks'
import GoogleMap from './components/GoogleMap'
import { RootState } from 'src/utilities/store'
import { SelectOption } from 'src/pages/DynamicPage/components/Select'
import { selectVenue } from 'src/models/group'
import RestaurantsList from './components/RestaurantsList'
import RestaurantCard from './components/RestaurantCard'
import { dayjs } from 'src/utilities/dayjs'
import { getWeekDay } from 'src/utilities/functions'
import { DayOfWeek } from 'src/types/api'
import ErrorModal from 'src/components/ErrorModal'

interface MapProps {
  tabletId?: string
  onOrderInTabletClick?: (tabletId: string) => void
}

const NearestRestaurantButtonContainer = styled.div`
  padding-top: 5rem;
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;

  @media ${({ theme }) => theme.queries.mobile} {
    padding-top: 2.5rem;
    max-width: none;
  }
`

const MapContainer = styled.div`
  padding-top: 2.5rem;
  width: 100%;
  height: 32rem;

  @media ${({ theme }) => theme.queries.mobile} {
    padding-top: 1.25rem;
    height: 16rem;
  }
`

const RestaurantContainer = styled.div`
  padding-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  align-items: center;

  @media ${({ theme }) => theme.queries.mobile} {
    padding-top: 1.25rem;
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
`

const getTime = (time?: string) => {
  if (!time) {
    return
  }

  return dayjs(time, 'HH:mm:ss').format('HH:mm')
}

const Map = ({ tabletId, onOrderInTabletClick }: MapProps) => {
  const [activeTabletId, setActiveTabletId] = useState<string>()
  const [errorMsg, setErrorMsg] = useState<string>('')

  const venues = useSelector((state: RootState) => state.group.group?.venues)

  const venue = useSelector((state: RootState) => selectVenue(state, activeTabletId))

  const { t } = useTranslation()

  const { loading, getNearestRestaurant } = useNearestRestaurant()

  const workingHours = useMemo(() => {
    let previousDayOfWeek: DayOfWeek | undefined
    return venue?.restaurantSchedules?.reduce<string>((text, restaurantSchedule, index) => {
      const dayOfWeek = restaurantSchedule.dayOfWeek
      if (!dayOfWeek) {
        return text
      }

      if (index > 0) {
        text += '\n'
      }

      if (previousDayOfWeek !== dayOfWeek) {
        text += getWeekDay(dayOfWeek)
      }

      text += `\t${getTime(restaurantSchedule.startTime)}-${getTime(restaurantSchedule.endTime)}`

      previousDayOfWeek = dayOfWeek

      return text
    }, '')
  }, [venue?.restaurantSchedules])

  const restaurantPositions = useMemo((): MarkerProps['position'][] => {
    return (venues ?? []).map((venue) => ({ lat: Number(venue.latitude), lng: Number(venue.longitude) }))
  }, [venues])

  const activeRestaurantPosition = useMemo((): MapCameraProps | undefined => {
    if (!venue) {
      return
    }

    return { center: { lat: Number(venue.latitude), lng: Number(venue.longitude) }, zoom: 15 }
  }, [venue])

  const restaurantOptions = useMemo((): SelectOption[] => {
    return (venues ?? []).map((venue) => ({ title: String(venue.restaurantDisplayTitle), value: String(venue.tabletId) }))
  }, [venues])

  const activeRestaurantOption = useMemo((): SelectOption | undefined => {
    if (!venue) {
      return
    }

    return { value: String(venue.tabletId), title: String(venue.restaurantDisplayTitle) }
  }, [venue])

  const clearErrorMsg = () => {
    setErrorMsg('')
  }

  const handleOrderInTabletClick = () => {
    if (!activeTabletId) {
      return
    }

    onOrderInTabletClick?.(activeTabletId)
  }

  const handleNearestRestaurantClick = async () => {
    try {
      const nearestRestaurant = await getNearestRestaurant()

      setActiveTabletId(nearestRestaurant.tabletId)
    } catch (error) {
      // @ts-expect-error
      setErrorMsg(t(`geolocationErrors.${error as number}`))
    }
  }

  useEffect(() => {
    if (!tabletId) {
      return
    }

    setActiveTabletId(tabletId)
  }, [tabletId])

  return (
    <>
      <NearestRestaurantButtonContainer>
        <NearestRestaurantButton variant="secondary" disabled={loading} onClick={handleNearestRestaurantClick}>
          {t('pages.dynamicPage.components.map.findNearestRestaurantButtonText')}
        </NearestRestaurantButton>
      </NearestRestaurantButtonContainer>

      <MapContainer>
        <GoogleMap activePosition={activeRestaurantPosition} positions={restaurantPositions} />
      </MapContainer>

      <RestaurantContainer>
        <RestaurantsList
          title={t('pages.dynamicPage.components.map.restaurantsListTitle')}
          options={restaurantOptions}
          activeOption={activeRestaurantOption}
          onRestaurantChange={setActiveTabletId}
        />

        <RestaurantCard
          address={venue?.address!}
          workingHours={workingHours}
          onButtonClick={handleOrderInTabletClick}
        />
      </RestaurantContainer>

      <ErrorModal
        errorMsg={errorMsg}
        buttonText={t('pages.dynamicPage.components.map.errorModalButtonText')}
        onBackClick={clearErrorMsg}
        onRetryClick={clearErrorMsg}
      />
    </>
  )
}

export default Map
