import { useController, FieldValues, UseControllerProps } from 'react-hook-form'
import { ChangeEvent, ReactNode } from 'react'

import Input from '../Input'
import Textarea from '../Textarea'
import DateInput from '../DateInput'
import Select, { SelectOption } from '../Select'
import Checkbox from '../Checkbox'
import {Color} from 'src/utilities/theme'

interface FormInputProps<T extends FieldValues> extends UseControllerProps<T> {
  type?: 'text' | 'email' | 'number' | 'textarea' | 'date' | 'select' | 'checkbox'
  placeholder?: string
  height?: string
  icon?: string
  options?: SelectOption[]
  checkboxLabel?: ReactNode
  onlyIntegers?: boolean
  bgColor?: Color
  textColor?: Color
  onValueChange?: () => void
}

const FormInput = <T extends FieldValues>({
  type,
  placeholder,
  height,
  icon,
  options,
  checkboxLabel,
  onlyIntegers,
  bgColor,
  textColor,
  onValueChange,
  ...props
}: FormInputProps<T>) => {
  const {
    field,
    formState: { errors },
  } = useController(props)

  const error = !!errors[field.name]

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e)

    onValueChange?.()
  }

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(e)

    onValueChange?.()
  }

  const handleDateChange = (value: Date | null) => {
    field.onChange(value)

    onValueChange?.()
  }

  const handleSelectChange = (value: SelectOption) => {
    field.onChange(value)

    onValueChange?.()
  }

  const handleCheckboxChange = (value: boolean) => {
    field.onChange(value)

    onValueChange?.()
  }

  switch (type) {
    case 'text':
      return (
        <Input
          {...field}
          type="text"
          placeholder={placeholder}
          error={error}
          onlyIntegers={onlyIntegers}
          bgColor={bgColor}
          textColor={textColor}
          onChange={handleInputChange}
          {...props}
        />
      )
    case 'email':
      return (
        <Input
          {...field}
          type="email"
          placeholder={placeholder}
          error={error}
          onlyIntegers={onlyIntegers}
          onChange={handleInputChange}
          {...props}
        />
      )
    case 'number':
      return (
        <Input
          {...field}
          type="number"
          placeholder={placeholder}
          error={error}
          onlyIntegers={onlyIntegers}
          onChange={handleInputChange}
        />
      )
    case 'textarea':
      return (
        <Textarea {...field} placeholder={placeholder} error={error} height={height} onChange={handleTextareaChange} />
      )
    case 'date':
      return (
        <DateInput
          disabled={field.disabled}
          name={field.name}
          value={field.value}
          selected={field.value}
          placeholderText={placeholder}
          error={error}
          icon={icon}
          onBlur={field.onBlur}
          onChange={handleDateChange}
        />
      )
    case 'select':
      return (
        <Select
          optionsVisibleCount={3}
          placeholder={placeholder}
          disabled={field.disabled}
          error={error}
          icon={icon}
          options={options}
          option={field.value}
          bgColor={bgColor}
          textColor={textColor}
          onOptionSelect={handleSelectChange}
        />
      )
    case 'checkbox':
      return (
        <Checkbox error={error} checked={field.value} disabled={field.disabled} onChange={handleCheckboxChange}>
          {checkboxLabel}
        </Checkbox>
      )
    default:
      return null
  }
}

export default FormInput
